import React from 'react'
import { Card,Button,Row,Col } from 'react-bootstrap';
import Icon from '@mdi/react'
import { mdiThumbUpOutline,mdiThumbDownOutline } from '@mdi/js'
import {useDispatch, useSelector} from 'react-redux' 
import {inquilinosInteresados} from '../redux/inquilinosInteresadosDucks'
import { modificarPropiedadesPosibles } from '../redux/ModificarPropiedadPosibleDucks'
import { ingresarNotificacion } from '../redux/NotificacionesDucks'
import { modificarPropiedadesNotificacion } from '../redux/ModificarPropiedadesFlagNotificacion';
import { notificacionesLeidasModificar } from '../redux/ModificarNotificacionesLeidasDucks';
import { obtenerNotificacionesMeGustaBadge } from '../redux/ObtenerNotificacionesMeGustaBadge';
import { obtenerNotificacionesMeGustaGeneral } from '../redux/ObtenerNotificacionesMeGustaGeneral';
import { obtenerNotificacionesMeGusta } from '../redux/ObtenerNotificacionesMeGusta';

const MisInquilinosInteresados = (props) => {


    const inquilinos = useSelector(store => store.inquilinosInteresados)
    const propiedadesPosibles = useSelector(store => store.propiedadesPosibles)
    const respuestaModificacionLeidas = useSelector(store => store.notificacionesLeidasModificar)
    const obtenerNotMeGustaBadge = useSelector(store => store.obtenerNotificacionesMeGustaBadge)

    const dispatch = useDispatch()



    const [data,setData] = React.useState(null)
        
    
 /*    React.useEffect(() => {

        setData(Array.from(inquilinos.results))

    },[inquilinos.results]) */


 
    const match = (id_inquilino,id_busqueda,id_propiedad) => {
        dispatch(modificarPropiedadesPosibles(id_inquilino,id_busqueda,id_propiedad,0,0,0,1,0))
        dispatch(inquilinosInteresados(id_propiedad))

        


        //dispatch(inquilinosInteresados(props.propIdDefault))

        let datosNotificacion = {
            user1 : localStorage.getItem("id_usuario").replaceAll('"',''),
            user2: id_inquilino,
            tipo: 'match',
            leido: 0,
            propiedadId: id_propiedad,
            id_busqueda: id_busqueda
        }
    
        dispatch(ingresarNotificacion(datosNotificacion))
        dispatch(notificacionesLeidasModificar(id_propiedad,id_busqueda))


    }

    const noAceptar = (id_inquilino,id_busqueda,id_propiedad) => {
        dispatch(modificarPropiedadesPosibles(id_inquilino,id_busqueda,id_propiedad,0,0,0,0,1))
        dispatch(inquilinosInteresados(id_propiedad))

        //dispatch(inquilinosInteresados(props.propIdDefault))

        let datosNotificacion = {
            user1 : localStorage.getItem("id_usuario").replaceAll('"',''),
            user2: id_inquilino,
            tipo: 'no aceptado',
            leido: 0,
            propiedadId: id_propiedad,
            id_busqueda: id_busqueda
        }
    
        dispatch(ingresarNotificacion(datosNotificacion))

        dispatch(modificarPropiedadesNotificacion(id_propiedad,1))

        dispatch(notificacionesLeidasModificar(id_propiedad,id_busqueda))
    

    }


    //prueba
    React.useEffect(() => {
        if(respuestaModificacionLeidas.isFetching === false && respuestaModificacionLeidas.results !== null){
            if(respuestaModificacionLeidas.results !== null && respuestaModificacionLeidas.status === 'ok' ){
                dispatch(obtenerNotificacionesMeGusta(localStorage.getItem("id_usuario").replaceAll('"',''), props.propIdDefault))
                dispatch(obtenerNotificacionesMeGustaBadge(localStorage.getItem("id_usuario").replaceAll('"',''), props.propIdDefault))
                dispatch(obtenerNotificacionesMeGustaGeneral(localStorage.getItem("id_usuario").replaceAll('"','')))
            }
        }
        
    },[respuestaModificacionLeidas])
    
    
    React.useEffect(() => {
        if(obtenerNotMeGustaBadge.isFetching === false && obtenerNotMeGustaBadge.results !== null){
            if(obtenerNotMeGustaBadge.results !== null && obtenerNotMeGustaBadge.status === 'ok' ){
                if(obtenerNotMeGustaBadge.results.result.cantidad == 0){
                    dispatch(modificarPropiedadesNotificacion(props.propIdDefault,0))
                }

            }
        }
    },[obtenerNotMeGustaBadge])

    return (
        <Card className="text-center borde">
                                     <Card.Header>

                                     </Card.Header>
                                     <Card.Body>
                                     <Card.Title>Inquilinos interesados en mi propiedad {props.propIdDefault}</Card.Title>

                                        {/* /////////////////////////// */}
                                        <Card className="text-center borde mb-3 justify-content-center">
                                            {/* <Card.Header>
                                            </Card.Header> */}
                                            <Card.Body>

                                                <Col>
                                                
                                 {inquilinos.results.length !== 0 ? (       
                                    inquilinos.status === 'ok' && inquilinos.results !== null &&
                                    inquilinos.results.map(item => (
                                        <Card
                                            className="mb-2"
                                        >
                                        <Card.Header>{item.nombre}</Card.Header>
                                                  <Card.Body>

                                                <Row className="justify-content-center p-4">
                                                
                                                <Col md={6} xs={12} >
                                                    <Button variant="danger" block  className="p-1 m-2" onClick={() => noAceptar(item.id_inquilino,item.id_busqueda,item.id_propiedad) }>
                                                        <Icon path={mdiThumbDownOutline} title="silverware" size={1} className="align-middle colorIconos" />
                                                    </Button>
                                                    <Button variant="success" block  className="p-1 m-2" onClick={() => match(item.id_inquilino,item.id_busqueda,item.id_propiedad) }>
                                                        <Icon path={mdiThumbUpOutline} title="silverware" size={1} className="align-middle colorIconos" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                            </Card.Body>
                                        </Card>
                                        )) 
                                        ) : (
                                            <div className="card mt-4 text-center">
                                            <div className="card-body">
                                            <p>No tiene ningun interesado aun</p>
                                            </div>
                                            </div>)
                                        } 
                                        
                                        
                                    </Col>
                                            
                                    </Card.Body>
                                    </Card>
                                        
                                        
                                        {/* /////////////////////////// */}
                                     </Card.Body>
                                     </Card>
                                     
    )
}

export default MisInquilinosInteresados
