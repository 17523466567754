import axios from 'axios'
import {urlApi} from '../services/apirest'

//3-constantes
const dataInicial = {
    status: '',
    results: [],
    isFetching : false,
    next: null,
    previous: null
}


//Types

//ALL
const OBTENER_NOTIFICACIONES_ME_GUSTA_EXITO = 'OBTENER_NOTIFICACIONES_ME_GUSTA_EXITO'
const OBTENER_NOTIFICACIONES_ME_GUSTA_ERROR = 'OBTENER_NOTIFICACIONES_ME_GUSTA_ERROR'
const OBTENER_NOTIFICACIONES_ME_GUSTA_ESPERANDO = 'OBTENER_NOTIFICACIONES_ME_GUSTA_ESPERANDO'

export default function obtenerNotificacionesMeGustaReducer(state = dataInicial, action){
    switch(action.type){

        case OBTENER_NOTIFICACIONES_ME_GUSTA_ESPERANDO:
            return {
                ...state,
                isFetching: true
                
            }

        case OBTENER_NOTIFICACIONES_ME_GUSTA_ERROR:
            return {...state, 
                isFetching: false,
                status: action.payload.status,
                results: action.payload.result
                
            }

        case OBTENER_NOTIFICACIONES_ME_GUSTA_EXITO:
            return {
                ...state,
                isFetching: false,
                status: 'ok',
                results: action.payload,//.result.datos,
                next: action.payload,//.result.next,
                previous: action.payload,//.result.previous
                
            }

            default:
                return state
        }
    }

    //acciones
//con el dispatch activamos el reducer y con el getstate obtenemos la data inicial
export const obtenerNotificacionesMeGusta = (idUsuario,propiedadId) => async (dispatch) => {
    

    const url = urlApi + 'notificaciones'

    dispatch({
        type: OBTENER_NOTIFICACIONES_ME_GUSTA_ESPERANDO,
    })

    try {  
        
        const res = await axios.get(url+'?user2='+idUsuario+'&tipo="me gusta"&propiedadId='+propiedadId+'')
        
        
            dispatch({
                type: OBTENER_NOTIFICACIONES_ME_GUSTA_EXITO,
                //Devolvemos toda la data ahora
                payload: res.data
            })
            


    }catch (error) {

        dispatch({
            type: OBTENER_NOTIFICACIONES_ME_GUSTA_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }
}