import { mdiCardMinusOutline } from '@mdi/js'
import axios from 'axios'
import {urlApi} from '../services/apirest'

//3-constantes
const dataInicial = {
    status: '',
    results: {result: {cantidad: null}},
    isFetching : false,
}


//Types

//ALL
const OBTENER_NOTIFICACIONES_ACEPTADA_GENERAL_EXITO = 'OBTENER_NOTIFICACIONES_ACEPTADA_GENERAL_EXITO'
const OBTENER_NOTIFICACIONES_ACEPTADA_GENERAL_ERROR = 'OBTENER_NOTIFICACIONES_ACEPTADA_GENERAL_ERROR'
const OBTENER_NOTIFICACIONES_ACEPTADA_GENERAL_ESPERANDO = 'OBTENER_NOTIFICACIONES_ACEPTADA_GENERAL_ESPERANDO'

export default function obtenerNotificacionesAceptadaGeneralReducer(state = dataInicial, action){
    switch(action.type){

        case OBTENER_NOTIFICACIONES_ACEPTADA_GENERAL_ESPERANDO:
            return {
                ...state,
                isFetching: true
                
            }

        case OBTENER_NOTIFICACIONES_ACEPTADA_GENERAL_ERROR:
            return {...state, 
                isFetching: false,
                status: action.payload.status,
                results: action.payload.result
                
            }

        case OBTENER_NOTIFICACIONES_ACEPTADA_GENERAL_EXITO:
            return {
                ...state,
                isFetching: false,
                status: 'ok',
                results: action.payload,//.result.datos,
                
            }

            default:
                return state
        }
    }

    //acciones
//con el dispatch activamos el reducer y con el getstate obtenemos la data inicial
export const obtenerNotificacionesAceptadaGeneral = (idUsuario) => async (dispatch) => {
    

    const url = urlApi + 'notificaciones'

    dispatch({
        type: OBTENER_NOTIFICACIONES_ACEPTADA_GENERAL_ESPERANDO,
    })

    try {  
    
        const res = await axios.get(url+'?user2='+idUsuario+'&tipo="match"')

  
            dispatch({
                type: OBTENER_NOTIFICACIONES_ACEPTADA_GENERAL_EXITO,
                //Devolvemos toda la data ahora
                payload: res.data
            })
            


    }catch (error) {

        dispatch({
            type: OBTENER_NOTIFICACIONES_ACEPTADA_GENERAL_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }
}