import React from 'react'
import { Card,Row,Col,Carousel,ListGroup,ListGroupItem } from 'react-bootstrap';
import PRUEBA2 from '../img/PRUEBA2.jpg';
import Icon from '@mdi/react'
import { mdiBedKing,mdiShower,mdiCurrencyUsd,mdiCompassOutline,mdiDog,mdiRulerSquare } from '@mdi/js'
import {useDispatch, useSelector} from 'react-redux' 


const TabNuevo = (props) => {

    const dispatch = useDispatch()


    const propiedad = useSelector(store => store.propiedades.unaPropiedad)

    return (
        
        
        <>
        
        <Card.Title>Busqueda id: {props.datos.idBusqueda} / Id prop: {props.id}</Card.Title>
        {propiedad !== undefined ? (
        <>
        <Carousel controls={false}>
        <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={PRUEBA2}
                    alt="First slide"
                  />

                </Carousel.Item>
                <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={PRUEBA2}
                    alt="Second slide"
                  />


                </Carousel.Item>

            </Carousel>


        <ListGroup className="list-group-flush">
            <ListGroupItem>
                <Row className="justify-content-center p-4">
                <Col md={4} xs={12}>
                        <Icon path={mdiCurrencyUsd} title="silverware" size={1} className="align-middle colorIconos p-0.8"/> 
                        {propiedad.precioDesdeAlquiler}
                </Col>
                <Col md={4} xs={12}>
                        <Icon path={mdiBedKing} title="silverware" size={1} className="align-middle colorIconos p-0.8"/>
                        {propiedad.dormitorios}
                </Col>
                <Col md={4} xs={12}>
                        <Icon path={mdiShower} title="silverware" size={1} className="align-middle colorIconos p-0.8"/>
                        {propiedad.banos}
                </Col>
                </Row>
          </ListGroupItem>
          <ListGroupItem>
                <Row className="justify-content-center p-4">
                <Col md={4} xs={12}>
                        <Icon path={mdiDog} title="silverware" size={1} className="align-middle colorIconos p-0.8"/> 
                        {propiedad.mascotas}
                </Col>
                <Col md={4} xs={12}>
                        <Icon path={mdiCompassOutline} title="silverware" size={1} className="align-middle colorIconos p-0.8"/>
                        {propiedad.orientacion}
                </Col>
                <Col md={4} xs={12}>
                        <Icon path={mdiRulerSquare} title="silverware" size={1} className="align-middle colorIconos p-0.8"/>
                        {propiedad.metrosEdificados}
                </Col>
                </Row>
          </ListGroupItem>
        </ListGroup>

        
        </>
        ):(
            <p>No tiene ninguno</p>
        )}
        </>
        
       

    
    )
}

export default TabNuevo
