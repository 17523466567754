import axios from 'axios'
import {urlApi} from '../services/apirest'

//3-constantes
const dataInicial = {
    status: '',
    results: [],
    isFetching : false,
}


//Types

//MODIFICAR
const MODIFICAR_PROPIEDADES_NOTIFICACION_EXITO = 'MODIFICAR_PROPIEDADES_NOTIFICACION_EXITO'
const MODIFICAR_PROPIEDADES_NOTIFICACION_ERROR = 'MODIFICAR_PROPIEDADES_NOTIFICACION_ERROR'
const MODIFICAR_PROPIEDADES_NOTIFICACION_ESPERANDO = 'MODIFICAR_PROPIEDADES_NOTIFICACION_ESPERANDO'


//reducer
export default function propiedadesNotificacionModificarReducer(state = dataInicial, action){
    switch(action.type){

        case MODIFICAR_PROPIEDADES_NOTIFICACION_ESPERANDO:
            return {
                ...state,
                isFetching: true
                
            }

        case MODIFICAR_PROPIEDADES_NOTIFICACION_ERROR:
            return {...state, 
                isFetching: false,
                status: action.payload.status,
                results: action.payload.result
                
            }

        case MODIFICAR_PROPIEDADES_NOTIFICACION_EXITO:
            return {
                ...state,
                isFetching: false,
                status: 'ok',
                results: action.payload.result.datos,
                
            }

            default:
                return state
        
    
            }
        }

//******ME QUEDE TRANCADA ACA, ME DA 401 NO AUTOIRZADO, VERIFICAR */
//acciones
//con el dispatch activamos el reducer y con el getstate obtenemos la data inicial
export const modificarPropiedadesNotificacion = (id_propiedad,notificacionFlag) => async (dispatch) => {
    
    const url = urlApi + 'propiedades'

    dispatch({
        type: MODIFICAR_PROPIEDADES_NOTIFICACION_ESPERANDO,
    })

    try {  
        const token = localStorage.getItem("token").toString().substring(1,33)

        const res = await axios.put(url+'?propiedadId='+id_propiedad+'&token='+token+'&notificacionFlag='+notificacionFlag+"")

            dispatch({
                type: MODIFICAR_PROPIEDADES_NOTIFICACION_EXITO,
                //Devolvemos toda la data ahora
                payload: res.data
            })
            
    }catch (error) {
        
        dispatch({
            type: MODIFICAR_PROPIEDADES_NOTIFICACION_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }
}
