import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {obtenerPropiedadesMatch, siguientePaginaPropiedadesMatch, anteriorPaginaPropiedadesMatch} from '../redux/PropiedadesMatchDucks'
import {ingresarPropiedadesPosibles} from '../redux/IngresoPropiedadesPosiblesDucks'


const Match = (props) => {

 //Dispatch es el que llama al accion
 const dispatch = useDispatch()
 const propiedades = useSelector(store => store.propiedadesMatch)//.results) 
 const next = useSelector(store => store.propiedadesMatch.next) 
 const previous = useSelector(store => store.propiedadesMatch.previous)
 const ingresoPropiedadesPosibles = useSelector(store => store.ingresarPropiedadesPosibles)

    let [error_msg, setError_msg] = React.useState('') 


    React.useEffect(() => {
      
        if(propiedades.isFetching === false && propiedades.status === "error"){
            setError_msg(propiedades.results.error_msg)
        }

    },[propiedades])

    React.useEffect(() => {
        if(ingresoPropiedadesPosibles.isFetching === false && ingresoPropiedadesPosibles.results !== null){
            dispatch(obtenerPropiedadesMatch(props.datos.propiedadId,0,0,0,1,0))
        }

    },[ingresoPropiedadesPosibles])

    
    
    let msgError = []
    if(error_msg !== ''){
        msgError.push(<div className="alert alert-danger">{error_msg}</div>)
    }

    const actualizar = () => {
        dispatch(ingresarPropiedadesPosibles(props.datos))
    }


    


    return (
        <>
        {(propiedades.isFetching === true && propiedades.status === '') ? (<h1>cargando</h1>) :( 
        <>
        <div className="row">
            
            <div className="col-md-10">
            
                <h3>Match</h3>

                {msgError}
               
                
                
                <ul className="list-group mt-3">
                    
                        {             propiedades.results !== null ? (
                                      propiedades.status === 'ok'  &&
                                      propiedades.results.result.datos.map(item => (

                                        <li key={item.id_propiedad} className="list-group-item text-uppercase">
                                            {item.id_busqueda} --{item.id_propiedad} -- {item.id_inquilino} -- {item.propietarioId} -- {item.meGusta} -- {item.noMeGusta} -- {item.talVez} -- {item.aceptada} -- {item.noAceptada}
                                            <button 
                                            className="btn btn-dark btn-sm float-right"
                                            >
                                                Info
                                            </button>
                                            

                                        </li>
                                    ))
                                    ):(
                                        <p>No tiene ninguno</p>
                                    )  
                        }
                    </ul>
                


                    {previous !== null && next!== null ? (
                     
                     (previous.result.previous!==0) ? (
                     <button onClick={() => dispatch(anteriorPaginaPropiedadesMatch(props.datos.propiedadId,0,0,0,1,0)) } className="btn btn-dark">Anterior</button>
                     ):null
                     ):null}
 
                     {/* previous !== null && */ next!== null ? (
                       
                     (next.result.next) ? (
                     <button onClick={() => dispatch(siguientePaginaPropiedadesMatch(props.datos.propiedadId,0,0,0,1,0)) } className="btn btn-dark">Siguiente</button>
                     ):null
                     ):null}

        
            </div>


{/*         <div className="col-md-6">
                <h3>Detalle Propiedad</h3>
                <Propiedad/>
        </div> */}
        </div>
        
        </>
        )}
        </>
    )
}

export default Match