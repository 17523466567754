import axios from 'axios'
import {urlApi} from '../services/apirest'

//3-constantes
const dataInicial = {
    status: '',
    results: [],
    isFetching : false,

}


//Types

//ALL
const OBTENER_MATCH_LEIDO_EXITO = 'OBTENER_MATCH_LEIDO_EXITO'
const OBTENER_MATCH_LEIDO_ERROR = 'OBTENER_MATCH_LEIDO_ERROR'
const OBTENER_MATCH_LEIDO_ESPERANDO = 'OBTENER_MATCH_LEIDO_ESPERANDO'

export default function obtenerMatchLeidoReducer(state = dataInicial, action){
    switch(action.type){

        case OBTENER_MATCH_LEIDO_ESPERANDO:
            return {
                ...state,
                isFetching: true
                
            }

        case OBTENER_MATCH_LEIDO_ERROR:
            return {...state, 
                isFetching: false,
                status: action.payload.status,
                results: action.payload.result
                
            }

        case OBTENER_MATCH_LEIDO_EXITO:
            return {
                ...state,
                isFetching: false,
                status: 'ok',
                results: action.payload,//.result.datos,

                
            }

            default:
                return state
        }
    }

    //acciones
//con el dispatch activamos el reducer y con el getstate obtenemos la data inicial
export const obtenerMatchLeido = (idUsuario,id_busqueda,propiedadId) => async (dispatch) => {
    

    const url = urlApi + 'notificaciones'

    dispatch({
        type: OBTENER_MATCH_LEIDO_ESPERANDO,
    })

    try {  

        const res = await axios.get(url+'?user2='+idUsuario+'&tipo="match"&id_busqueda='+id_busqueda+'&propiedadId='+propiedadId+'')


            dispatch({
                type: OBTENER_MATCH_LEIDO_EXITO,
                //Devolvemos toda la data ahora
                payload: res.data
            })
            


    }catch (error) {

        dispatch({
            type: OBTENER_MATCH_LEIDO_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }
}