import axios from 'axios'
import {urlApi} from '../services/apirest'

//3-constantes
const dataInicial = {
    status: '',
    results: [],
    isFetching : false,
    next: null,
    previous: null
}

//ONE
const OBTENER_PROPIEDAD_EXITO = 'OBTENER_PROPIEDAD_EXITO'
const OBTENER_PROPIEDAD_ERROR = 'OBTENER_PROPIEDAD_ERROR'
const OBTENER_PROPIEDAD_ESPERANDO = 'OBTENER_PROPIEDAD_ESPERANDO'

//reducer
export default function unaPropiedadReducer(state = dataInicial, action){
    switch(action.type){
        case OBTENER_PROPIEDAD_ESPERANDO:
                return {
                    ...state,
                    isFetching: true
                            
                }
            
            case OBTENER_PROPIEDAD_ERROR:
                return {...state, 
                    isFetching: false,
                    status: action.payload.status,
                    results: action.payload.result
                            
                }
            
            case OBTENER_PROPIEDAD_EXITO:
                return {
                    ...state,
                    isFetching: false,
                    status: 'ok',
                    unaPropiedad: action.payload      
                 }
        
        default:
            return state
    }
}

export const obtenerPropiedad = (id) => async (dispatch) => {

    const url = urlApi + 'propiedades'


    dispatch({
        type: OBTENER_PROPIEDAD_ESPERANDO,
    })

    try {
        const res = await axios.get(url+'?id='+id)

        dispatch({
            type: OBTENER_PROPIEDAD_EXITO,
            payload: {
                propiedadId: res.data[0].propiedadId,
                precioDesdeAlquiler: res.data[0].precioDesdeAlquiler,
                dormitorios: res.data[0].dormitorios,
                banos: res.data[0].banos,
                orientacion: res.data[0].orientacion,
                mascotas: res.data[0].mascotas,
                metrosEdificados: res.data[0].metrosEdificados,
                }
        })


    } catch (error)  {

        dispatch({
            type: OBTENER_PROPIEDAD_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }

}