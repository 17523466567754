import axios from 'axios'
import {urlApi} from '../services/apirest'

//3-constantes
const dataInicial = {
    status: '',
    results: [],
    isFetching : false,
    next: null,
    previous: null
}



//INSERT POSIBLES
const INGRESAR_PROPIEDAD_POSIBLES_ESPERANDO = 'INGRESAR_PROPIEDAD_POSIBLES_ESPERANDO'
const INGRESAR_PROPIEDAD_POSIBLES_EXITO = 'INGRESAR_PROPIEDAD_POSIBLES_EXITO'
const INGRESAR_PROPIEDAD_POSIBLES_ERROR = 'INGRESAR_PROPIEDAD_POSIBLES_ERROR'
const RESET_DATOS = 'RESET_DATOS'





//reducer
export default function ingresarPropiedadesPosiblesReducer(state = dataInicial, action){
    switch(action.type){

            case INGRESAR_PROPIEDAD_POSIBLES_ESPERANDO:
                return {
                    ...state,
                    isFetching: true
                }

            case INGRESAR_PROPIEDAD_POSIBLES_EXITO:
                return {
                    ...state, 
                    isFetching: false,
                    status: action.payload.status,
                    results: action.payload.result
                }

            case INGRESAR_PROPIEDAD_POSIBLES_ERROR:
                return {
                    ...state, 
                    isFetching: false,
                    status: action.payload.status,
                    results: action.payload.result
                }

            case RESET_DATOS:
                return {
                    ...state,
                    isFetching: false,
                    status: action.payload.status,
                    results: action.payload.result
                }
        
        default:
            return state
    }
}





export const ingresarPropiedadesPosibles = (datos) => async (dispatch) => {
    
    const url = urlApi + 'propiedades'

    dispatch({
        type: INGRESAR_PROPIEDAD_POSIBLES_ESPERANDO,
    })

    try {  

        const res = await axios.get(url+'?precioDesdeAlquiler='+datos.precioDesdeAlquiler+'&dormitorios='+datos.dormitorios+'&banos='+datos.banos+'&orientacion='+datos.orientacion+'&mascotas='+datos.mascotas+'&id_inquilino='+datos.idUsuario+'&precioHastaAlquiler='+datos.precioHastaAlquiler+'&id_busqueda='+datos.idBusqueda+'')

            dispatch({
                type: INGRESAR_PROPIEDAD_POSIBLES_EXITO,
                //Devolvemos toda la data ahora
                payload: res.data
            })

    }catch (error) {
        
        dispatch({
            type: INGRESAR_PROPIEDAD_POSIBLES_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }
}