import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {obtenerPropiedades, siguientePaginaPropiedades, anteriorPaginaPropiedades, obtenerPropiedad} from '../redux/PropiedadesDucks'
import Propiedad from './Propiedad'

const Inicio3 = () => {

    //Dispatch es el que llama al accion
    const dispatch = useDispatch()
    const propiedades = useSelector(store => store.propiedades)//.results)
    const next = useSelector(store => store.propiedades.next) 
    const previous = useSelector(store => store.propiedades.previous)

    

    React.useEffect(() => {
        const fetchData = () => {
            dispatch(obtenerPropiedades())
            
        }
        fetchData()
    },[dispatch])

    let [error_msg, setError_msg] = React.useState('') 

    React.useEffect(() => {
        
        if(propiedades.isFetching === false && propiedades.status === "error"){
            setError_msg(propiedades.results.error_msg)

        }
    },[propiedades])
    
    let msgError = []
    if(error_msg !== ''){
        msgError.push(<div className="alert alert-danger">{error_msg}</div>)
    }
    

    return (
        <>
        {(propiedades.isFetching === true && propiedades.status === '') ? (<h1>cargando</h1>) :( 
        <>
        <div className="row">
            <div className="col-md-6">

                <h3>Lista propiedades</h3>
                {msgError}
                <ul className="list-group mt-3">
                        {             propiedades.status === 'ok' &&
                                      propiedades.results.map(item => (

                                        <li key={item.propiedadId} className="list-group-item text-uppercase">
                                            {item.propiedadId} -- {item.precioAlquiler} -- {item.dormitorios} -- {item.banos} -- {item.orientacion} -- {item.mascotas} -- {item.metrosEdificados}
                                            <button 
                                            className="btn btn-dark btn-sm float-right"
                                             onClick={() => dispatch(obtenerPropiedad(item.propiedadId))} 
                                            >
                                                Info
                                            </button>
                                        </li>
                                    )) 
                        }
                    </ul>
        {
                    (previous || previous!==0) &&
                    <button onClick={() => dispatch(anteriorPaginaPropiedades()) } className="btn btn-dark">Anterior</button>
        }
        {
                    (next) &&
                    <button onClick={() => dispatch(siguientePaginaPropiedades()) } className="btn btn-dark">Siguiente</button>
        }
        
            </div>


        <div className="col-md-6">
                <h3>Detalle Propiedad</h3>
                <Propiedad/>
        </div>
        </div>
        <div>
            <Link to = {'/menu/'}>Volver</Link>
        </div>
        </>
        )}
        </>
    )
}

export default Inicio3
