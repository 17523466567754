import React from 'react'
import { Card,Row,Col,ListGroup,ListGroupItem } from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux'
import { inquilinosInteresadosMatch } from '../redux/InquilinosMatch';

const MisInquilinosMatch = (props) => {
    const inquilinosMatch = useSelector(store => store.datosInquilinosMatch)
    const propiedadesPosibles = useSelector(store => store.propiedadesPosibles)

    const dispatch = useDispatch()

    React.useEffect(() => {
        const fetchData = () => {
        
            dispatch(inquilinosInteresadosMatch(props.propIdDefault))
            
        }
        fetchData()
    },[dispatch]) 




    return (
            <Card className="text-center borde">
                                     <Card.Header>

                                     </Card.Header>
                                     <Card.Body>
                                     <Card.Title>Matchs en mi propiedad nro.{props.propIdDefault}</Card.Title>

                                        {/* /////////////////////////// */}
                                        <Card className="text-center borde mb-3 justify-content-center">
                                            {/* <Card.Header>
                                            </Card.Header> */}
                                            <Card.Body>

                                                <Col>

                                    {inquilinosMatch.results.length !== 0 ? (       
                                    inquilinosMatch.status === 'ok' && inquilinosMatch.results !== null &&
                                    inquilinosMatch.results.map(item => (
                                                <Card
                                               
                                                  className="mb-2"

                                                >
                                                  <Card.Header>{item.nombre}</Card.Header>
                                                <Card.Body>

                                                <Row className="justify-content-center p-4">
                                                
                                                <Col md={12} xs={12} >
                                                <ListGroup className="list-group-flush">
                                                  <ListGroupItem>Celular: {item.celular}</ListGroupItem>
                                                  <ListGroupItem>Email: {item.email}</ListGroupItem>
                                                </ListGroup>
                                                </Col>
                                                </Row>
                                                </Card.Body>
                                                </Card>
                                    ))
                                    ):(
                                        <div className="card mt-4 text-center">
                                        <div className="card-body">
                                        <p>No tiene ningun interesado aun</p>
                                        </div>
                                        </div>)
                                    } 
                                        </Col>
                                            
                                    </Card.Body>
                                    </Card>
                                    </Card.Body>
                                    </Card>

    )
}

export default MisInquilinosMatch
