import axios from 'axios'
import {urlApi} from '../services/apirest'

//3-constantes
const dataInicial = {
    status: '',
    results: [],
    isFetching : false,
}

//Types

//ALL
const OBTENER_DATOS_INQUILINO_MATCH_EXITO = 'OBTENER_DATOS_INQUILINO_MATCH_EXITO'
const OBTENER_DATOS_INQUILINO_MATCH_ERROR = 'OBTENER_DATOS_INQUILINO_MATCH_ERROR'
const OBTENER_DATOS_INQUILINO_MATCH_ESPERANDO = 'OBTENER_DATOS_INQUILINO_MATCH_ESPERANDO'

//reducer
export default function datosInquilinosMatchReducer(state = dataInicial, action){
    switch(action.type){

        case OBTENER_DATOS_INQUILINO_MATCH_ESPERANDO:
            return {
                ...state,
                isFetching: true
                
            }

        case OBTENER_DATOS_INQUILINO_MATCH_ERROR:
            return {...state, 
                isFetching: false,
                status: action.payload.status,
                results: action.payload.result
                
            }

        case OBTENER_DATOS_INQUILINO_MATCH_EXITO:
            return {
                ...state,
                isFetching: false,
                status: 'ok',
                results: action.payload,
                
                
            }
        

        default:
            return state
    }
}

export const inquilinosInteresadosMatch = (id) => async (dispatch) => {
    

    const url = urlApi + 'propiedades'

    dispatch({
        type: OBTENER_DATOS_INQUILINO_MATCH_ESPERANDO,
    })

    try {  

        const res = await axios.get(url+'?propiedadId='+id+"&aceptada="+true)

        
            dispatch({
                type: OBTENER_DATOS_INQUILINO_MATCH_EXITO,
                payload: res.data
        
            })


    }catch (error) {

        dispatch({
            type: OBTENER_DATOS_INQUILINO_MATCH_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }
}