import React from 'react'
import { Tabs,Tab,Card,Button,Container,Row,Col,Badge } from 'react-bootstrap';
import Icon from '@mdi/react'
import { mdiReload,mdiThumbUpOutline,mdiThumbDownOutline,mdiClockTimeThreeOutline } from '@mdi/js'
import TabMeGusta from './TabMeGusta';
import TabNoMeGusta from './TabNoMeGusta';
import TabTalVez from './TabTalVez';
import TabMatch from './TabMatch';
import {useDispatch, useSelector} from 'react-redux'
import {obtenerPropiedadesPosibles} from '../redux/PropiedadesPosiblesDucks'
import {ingresarPropiedadesPosibles} from '../redux/IngresoPropiedadesPosiblesDucks'
import {modificarPropiedadesPosibles} from '../redux/ModificarPropiedadPosibleDucks'
import { ingresarNotificacion } from '../redux/NotificacionesDucks'
import TabNuevo from './TabNuevo';
import { obtenerPropiedad } from '../redux/ObtenerUnaPropiedadDucks';
import General from './General';
import { modificarPropiedadesNotificacion } from '../redux/ModificarPropiedadesFlagNotificacion';
import { obtenerNotificacionesAceptada } from '../redux/ObtenerNotificacionesAceptada';


const TabsInquilino = (props) => {

    //Dispatch es el que llama al accion
 const dispatch = useDispatch()
 const propiedades = useSelector(store => store.propiedadesPosibles)//.result) 
 const next = useSelector(store => store.propiedadesPosibles.next) 
 const previous = useSelector(store => store.propiedadesPosibles.previous)
 const ingresoPropiedadesPosibles = useSelector(store => store.ingresarPropiedadesPosibles)
 const ingresarNotificaciones = useSelector(store => store.ingresarNotificaciones)
 const notificaciones = useSelector(store => store.obtenerNotificaciones)
 const aceptada = useSelector(store => store.obtenerNotificacionesAceptada)
 const aceptadaGeneral = useSelector(store => store.obtenerNotificacionesAceptadaGeneral)
 const meGustaGeneral = useSelector(store => store.obtenerNotificacionesMeGustaGeneral)

    let [error_msg, setError_msg] = React.useState('') 
    const [show, setShow] = React.useState(true);
    const [show2, setShow2] = React.useState(true);

    React.useEffect(() => {

        if(propiedades.isFetching === false && propiedades.status === "error"){
            setError_msg(propiedades.results.error_msg+'error')
        }
        //dispatch(obtenerNotificacionesAceptadaGeneral(localStorage.getItem("id_usuario").substring(1,2)))
        dispatch(obtenerNotificacionesAceptada(localStorage.getItem("id_usuario").replaceAll('"',''), props.datos.idBusqueda))
        setShow(false)
        setShow2(false)


    },[propiedades])

    React.useEffect(() => {
         if(ingresoPropiedadesPosibles.isFetching === false && ingresoPropiedadesPosibles.results !== null){
      
            dispatch(obtenerPropiedadesPosibles(props.datos.propiedadId,0,0,0,0,0))

        } 

    },[ingresoPropiedadesPosibles])

    
    let msgError = []
    if(error_msg !== ''){
        msgError.push(<div className="alert alert-danger">{error_msg}</div>)
    }

    const actualizar = () => {
        dispatch(ingresarPropiedadesPosibles(props.datos))
    }

    
    let [cantidadAceptada, setCantidadAceptada] = React.useState(null)
    let [cantidadMeGusta, setCantidadMeGusta] = React.useState(null)
    let [cantidadBadgeMatch, setCantidadBadgeMatch] = React.useState(null)



React.useEffect(() => {
    setCantidadBadgeMatch(null)
    if(aceptada.isFetching === false && aceptada.results !== null){
        if(aceptada.results !== null && aceptada.status === 'ok' ){
            if(aceptada.results.result.cantidad){

                setCantidadBadgeMatch(aceptada.results.result.cantidad)}
            }}
},[aceptada])
    


    React.useEffect(() => {

        setShow(true)

            
        
    },[cantidadAceptada]) 

    React.useEffect(() => {

        setShow2(true)

            
        
    },[cantidadMeGusta]) 


    const meGusta = (id_busqueda,id_propiedad,propietarioId) => {
        dispatch(modificarPropiedadesPosibles(null,id_busqueda,id_propiedad,1,0,0,0,0))
        dispatch(ingresarPropiedadesPosibles(props.datos))


        let datosNotificacion = {
            user1 : localStorage.getItem("id_usuario").replaceAll('"',''),
            user2: propietarioId,
            tipo: 'me gusta',
            leido: 0,
            propiedadId: id_propiedad,
            id_busqueda: id_busqueda
        }

        dispatch(ingresarNotificacion(datosNotificacion))

        dispatch(modificarPropiedadesNotificacion(propiedades.results.result.datos[0].id_propiedad,1))
    }

    const talVez = (id_busqueda,id_propiedad) => {
        dispatch(modificarPropiedadesPosibles(null,id_busqueda,id_propiedad,0,0,1,0,0))
        dispatch(ingresarPropiedadesPosibles(props.datos))
    }

    const noMeGusta = (id_busqueda,id_propiedad) => {
        dispatch(modificarPropiedadesPosibles(null,id_busqueda,id_propiedad,0,1,0,0,0))
        dispatch(ingresarPropiedadesPosibles(props.datos))
    }

    React.useEffect(() => {
        if(propiedades.results !== null){
        if(propiedades.results.result){

        dispatch(obtenerPropiedad(propiedades.results.result.datos[0].id_propiedad))
            }}
    }, [propiedades.results])


/*     function tick() {
        const element = (
          <div>
            <h1>Hello, world!</h1>
            <h2>It is {new Date().toLocaleTimeString()}.</h2>
          </div>
        );
        // highlight-next-line
        ReactDOM.render(element, document.getElementById('id'));
      } */
      
      /* setInterval(tick, 1000); */
    
    /* const toggleShowA = () => setShowA(!showA); */

    return (
        
        <>
        {(propiedades.isFetching === true && propiedades.status === '') ? (<h1>cargando</h1>) :( 
        <>
        
            <Container fluid>
            <General/>

      
{/*             <ToastContainer className="p-3" position='bottom-end'>
            
                <Toast onClose={() => setShow(false)} show={show} delay={5000} autohide>
                      <Toast.Header>
                        <img
                          src="holder.js/20x20?text=%20"
                          className="rounded me-2"
                          alt=""
                        />
                        <strong className="me-auto">Match</strong>
                        <small>en este momento</small>
                      </Toast.Header>
                      <Toast.Body>Tienes {cantidadAceptada} match</Toast.Body>
                </Toast>  

                <Toast onClose={() => setShow2(false)} show={show2} delay={5000} autohide>
                      <Toast.Header>
                        <img
                          src="holder.js/20x20?text=%20"
                          className="rounded me-2"
                          alt=""
                        />
                        <strong className="me-auto">Me gusta</strong>
                        <small>en este momento</small>
                      </Toast.Header>
                      <Toast.Body className="me-auto">Tienes {cantidadMeGusta} me gusta</Toast.Body>
                </Toast>  


            </ToastContainer> */}


        

           

           {/*  <MisBusquedas/> */}
            <Tabs defaultActiveKey="nuevo" id="uncontrolled-tab-example" className="mb-3 justify-content-center">
            
            {/* TAB NUEVO */}
            <Tab eventKey="nuevo" title="Nuevo">
                <Row className="justify-content-center p-1">
                    <Col md={4} xs={12}>
                    
                    {   propiedades.results !== null ? (
                        propiedades.status === 'ok' &&
                        propiedades.results.result.datos.map(item => (

                        <Card className="text-center borde" key={item.id_propiedad}>
                        <Card.Header>
                        
                        </Card.Header>
                        <Card.Body>
                        
                          
                       <TabNuevo datos={props.datos} id={item.id_propiedad}/>

                       <Card.Footer className="colorH3">
                        <Row className="justify-content-center p-4">

                            <Col md={4} xs={12}>
                                <Button variant="danger" onClick={() => noMeGusta(item.id_busqueda,item.id_propiedad) }>
                                    <Icon path={mdiThumbDownOutline} title="silverware" size={1} className="align-middle colorIconos" />
                                </Button>
                            </Col>
                            <Col md={4} xs={12}>
                                <Button variant="warning" onClick={() => talVez(item.id_busqueda,item.id_propiedad) }>
                                    <Icon path={mdiClockTimeThreeOutline } title="silverware" size={1} className="align-middle colorIconos" />
                                </Button>
                            </Col>

                            <Col md={4} xs={12}>
                                <Button variant="success" onClick={() => meGusta(item.id_busqueda,item.id_propiedad,item.propietarioId) }>
                                    <Icon path={mdiThumbUpOutline} title="silverware" size={1} className="align-middle colorIconos" />
                                </Button>
                            </Col>
                        </Row>  
                        </Card.Footer>
                        </Card.Body>
                        </Card>

                        )) 
                        ):(
                            <Card className="text-center borde">
                                <Card.Header>

                                </Card.Header>
                                <Card.Body>
                                <Card.Title>No hay nuevas opciones</Card.Title>
                                <Button variant="secondary" onClick={() => actualizar() }>
                                    <Icon path={mdiReload} title="silverware" size={1} className="align-middle colorIconos" />
                                </Button>
                                 
                                </Card.Body>
                            </Card>
                        )
                        }

                    </Col>   
                </Row >   
                </Tab>

                {/* ME GUSTA */}
                <Tab eventKey="megusta" title="Me Gusta">
                <Row className="justify-content-center p-1">
                    <Col md={4} xs={12}>
                        <TabMeGusta datos={props.datos}/>
                    </Col>   
                </Row >     
                </Tab>

                {/* NO ME GUSTA */}
                <Tab eventKey="nomegusta" title="No Me Gusta">

                <Row className="justify-content-center p-1">
                    <Col md={4} xs={12}>
                        <TabNoMeGusta datos={props.datos}/>
                    </Col>   
                </Row >   
                </Tab>

               {/*  TAL VEZ */}
                <Tab eventKey="talvez" title="Tal vez">

                <Row className="justify-content-center p-1">
                    <Col md={4} xs={12}>
                        <TabTalVez datos={props.datos}/>
                    </Col>   
                </Row >   
                </Tab>

                {/* MATCH */}
                <Tab eventKey="match" /* title="Match" */ title={<React.Fragment>Match   <Badge bg='warning' text="dark">{cantidadBadgeMatch}</Badge></React.Fragment>} >
                
                <Row className="justify-content-center p-1">
                    <Col md={4} xs={12}>
                        <TabMatch datos={props.datos}/>
                    </Col>   
                </Row >   
                </Tab>
                </Tabs> 
               
               
            </Container>
            

        </>
            )} 
        </>
    )
}

export default TabsInquilino
