import axios from 'axios'
import {urlApi} from '../services/apirest'

//constantes
const dataInicial = {
    status : "",
    result : [],
    conectado : false,
    isFetching : false
}

//Types
const LOGIN_USUARIO_EXITO = 'LOGIN_USUARIO_EXITO'
const LOGIN_USUARIO_ERROR = 'LOGIN_USUARIO_ERROR'
const LOGIN_USUARIO_ESPERANDO = 'LOGIN_USUARIO_ESPERANDO'


//reducer
export default function LoginReducer(state = dataInicial, action){
    switch(action.type){

        case LOGIN_USUARIO_ESPERANDO:
            return {
                ...state,
                isFetching: true,
                conectado : false
            }

        case LOGIN_USUARIO_EXITO:
            return {...state, 
                isFetching: false, 
                status: action.payload.status,
                result: action.payload,
                conectado : true
            }

        case LOGIN_USUARIO_ERROR:
            return {
                ...state,
                status: action.payload.status,
                isFetching: false,
                result: action.payload.result
            }
        
        default:
            return state
    }
}

//acciones
//con el dispatch activamos el reducer y con el getstate obtenemos la data inicial
export const login = (datos) => async (dispatch) => {

    const url = urlApi + 'auth'

    dispatch({
        type: LOGIN_USUARIO_ESPERANDO,
    })

    try {
        //Comente para que no de error por mientras, hay que descomentar
        const res = await axios.post(url, datos)

        if(res.data.status === "ok"){

            dispatch({
                type : LOGIN_USUARIO_EXITO,
                payload : {status: res.data.status,
                            result:res.data.result
                            }
            })

            localStorage.setItem('token',JSON.stringify(res.data.result.token))
            localStorage.setItem('id_usuario',JSON.stringify(res.data.result.id_usuario))
            localStorage.setItem('name_usuario',JSON.stringify(res.data.result.nombre_usuario))

        }else{

            dispatch({
                type: LOGIN_USUARIO_ERROR,
                payload: {status: res.data.status,
                            result: res.data.result
                        }
        })
        }
     

    }catch (error) {
        dispatch({
            type: LOGIN_USUARIO_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }


 
} 