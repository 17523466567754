import React from 'react'
import {Link} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {ingresarPropiedad, resetDatos} from '../redux/IngresoPropiedadDucks'
import {ingresarPropiedadesPosibles} from '../redux/IngresoPropiedadesPosiblesDucks'


const IngresarPropiedad = (props) => {

    const [precioDesdeAlquiler, setPrecioDesdeAlquiler] = React.useState('')
    const [precioHastaAlquiler, setPrecioHastaAlquiler] = React.useState('')
    const [dormitorios, setDormitorios] = React.useState('')
    const [banos, setBanos] = React.useState('')
    const [orientacion, setOrientacion] = React.useState('')
    const [mascotas, setMascotas] = React.useState('')
    const [metrosEdificados, setMetrosEdificados] = React.useState('')
    const [tipoPropietario, setTipoPropietario] = React.useState(props.tipoPropietario)
    const [tipoInquilino, setTipoInquilino] = React.useState(props.tipoInquilino)
    

    const dispatch = useDispatch();
    

    const guardarPropiedad = (e) => {

        e.preventDefault();

        
        let datos = {
            tipoPropietario: tipoPropietario,
            tipoInquilino: tipoInquilino,
            precioDesdeAlquiler: precioDesdeAlquiler,
            precioHastaAlquiler: precioHastaAlquiler,
            dormitorios: dormitorios,
            banos: banos,
            orientacion: orientacion,
            mascotas: mascotas,
            metrosEdificados: metrosEdificados,
            token: localStorage.getItem('token').toString().substring(1,33),
            idUsuario: localStorage.getItem('id_usuario').match(/(\d+)/g)[0]
        }


        dispatch(ingresarPropiedad(datos))

        

        e.target.reset()

        datos = {
            tipoPropietario: false,
            tipoInquilino: false,
            precioDesdeAlquiler: '',
            precioHastaAlquiler: '',
            dormitorios: '',
            banos: '',
            orientacion: '',
            mascotas: '',
            metrosEdificados: '',
            token: '',
            idUsuario: ''
        }
        }
 
        let resultadoIngresoPropiedad = useSelector((store) => store.ingresarPropiedad)


        


        let resultadoIngresoPropiedadPosible = useSelector((store) => store.ingresarPropiedadesPosibles)



        let [error_msg, setError_msg] = React.useState('') 
        let [ok_msg, setOk_msg] = React.useState('')
        let [cargando_msg, setCargando_msg] = React.useState('')
        const msgOk = React.useRef(null)
        const msgError = React.useRef(null)
        const msgCargando = React.useRef(null)


        React.useEffect(() => {

           if(resultadoIngresoPropiedad.isFetching === true) {
                setCargando_msg('Cargando')
                if(cargando_msg === ''){
                    msgCargando.current.style.display = "block"
                    
                    }else{
                        msgCargando.current.style.display = "none"
                        setCargando_msg()
                }

            }else{
                
                if(resultadoIngresoPropiedad.status === "error"){
                    msgCargando.current.style.display = "none"
                    setCargando_msg('')
                    setError_msg(resultadoIngresoPropiedad.results.error_msg)
                    if(error_msg === ''){
                        msgError.current.style.display = "block"
                        
                        }else{
                        msgError.current.style.display = "none"
                        setError_msg()
                    }
                    
                }
    
                if(resultadoIngresoPropiedad.status === "ok" && resultadoIngresoPropiedad.results !== null){
                    msgCargando.current.style.display = "none"
                    setCargando_msg('')
                    setOk_msg('La propiedad se a ingresado correctamente '+resultadoIngresoPropiedad.results)

                    if(tipoInquilino === true){
                        guardarPropiedadesPosibles()
                    }

                    if(ok_msg === ''){
                    msgOk.current.style.display = "block"
                    
                    }else{
                    msgOk.current.style.display = "none"
                    setOk_msg()
                    }

                    //Seteo a cero
                    setPrecioDesdeAlquiler('')
                    setPrecioHastaAlquiler('')
                    setDormitorios('')
                    setBanos('')
                    setOrientacion('')
                    setMascotas('')
                    setMetrosEdificados('')
                    setTipoPropietario(false)
                    setTipoInquilino(false)
                       
                }
            }  

        }, [resultadoIngresoPropiedad])     


        const guardarPropiedadesPosibles = () => {

            let datos2 = {
                tipoPropietario: tipoPropietario,
                tipoInquilino: tipoInquilino,
                precioDesdeAlquiler: precioDesdeAlquiler,
                precioHastaAlquiler: precioHastaAlquiler,
                dormitorios: dormitorios,
                banos: banos,
                orientacion: orientacion,
                mascotas: mascotas,
                metrosEdificados: metrosEdificados,
                token: localStorage.getItem('token').toString().substring(1,33),
                idUsuario: localStorage.getItem('id_usuario').match(/(\d+)/g)[0],
                idBusqueda: resultadoIngresoPropiedad.results
            }

            dispatch(ingresarPropiedadesPosibles(datos2))

        datos2 = {
            tipoPropietario: false,
            tipoInquilino: false,
            precioDesdeAlquiler: '',
            precioHastaAlquiler: '',
            dormitorios: '',
            banos: '',
            orientacion: '',
            mascotas: '',
            metrosEdificados: '',
            token: '',
            idUsuario: '',
            idBusqueda: ''
        }

        }

        
         const volver = () => {
            dispatch(resetDatos())
            setTipoPropietario(false)
            setTipoInquilino(false)
        } 
        

    return (
        
        
        <div className="card mt-4 text-center">
            <div className="card-body">
                <form onSubmit={guardarPropiedad}>
                    <input 
                    type="text" 
                    className="form control mb-2"
                    placeholder={'Precio desde alquiler'}
                    onChange={e=>setPrecioDesdeAlquiler(e.target.value)}
                    //value={tarea}
                    />
                    <br/>
                    <input 
                    type="text" 
                    className="form control mb-2"
                    placeholder={'Precio hasta alquiler'}
                    onChange={e=>setPrecioHastaAlquiler(e.target.value)}
                    //value={tarea}
                    />
                    <br/>
                    <input 
                    type="text" 
                    className="form control mb-2"
                    placeholder={'Dormitorios'}
                    onChange={e=>setDormitorios(e.target.value)}
                    //value={tarea}
                    /> 
                    <br/>
                    <input 
                    type="text" 
                    className="form control mb-2"
                    placeholder={'Baños'}
                    onChange={e=>setBanos(e.target.value)}
                    //value={tarea}
                    />  
                    <br/>
                    <input 
                    type="text" 
                    className="form control mb-2"
                    placeholder={'Orientacion'}
                    onChange={e=>setOrientacion(e.target.value)}
                    //value={tarea}
                    /> 
                    <br/>
                    <input 
                    type="text" 
                    className="form control mb-2"
                    placeholder={'Aceptan mascotas?'}
                    onChange={e=>setMascotas(e.target.value)}
                    //value={tarea}
                    /> 
                    <br/>
                    <input 
                    type="text" 
                    className="form control mb-2"
                    placeholder={'Metros edificados'}
                    onChange={e=>setMetrosEdificados(e.target.value)}
                    //value={tarea}
                    /> 
                <br></br>

                
                <button type="submit">Guardar</button>
                <br></br>

                </form>

                <div className="alert alert-danger" ref={msgError} style={{display:'none'}}>{error_msg}</div>
                <div className="alert alert-success" ref={msgOk} style={{display:'none'}}>{ok_msg}</div>
                <div className="alert alert-secondary" ref={msgCargando} style={{display:'none'}}>{cargando_msg}</div>
                
            </div>

            <div>
            <Link to = {'/menu'} onClick={volver} >Volver</Link>
            </div>

        </div>
     
        
        
    )
}

export default IngresarPropiedad
