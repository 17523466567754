import React from 'react'
import {useDispatch, useSelector} from 'react-redux' 
import {obtenerPropiedad} from '../redux/PropiedadesDucks'

const Propiedad = () => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        const fetchData = () => {
            dispatch(obtenerPropiedad())
        }
        fetchData()
    },[dispatch])

    const propiedad = useSelector(store => store.propiedades.unaPropiedad)

    //si pokemon existe (pokemon ?), sino devolvemos null (: null)
    return propiedad ? (
        <div className="card mt-4 text-center">
            <div className="card-body">
                <div className="card-title text-uppercase">{propiedad.namee}</div>
                <p className="card-text">Id: {propiedad.propiedadId} | Precio alquiler: {propiedad.precioAlquiler} | Dormitorios: {propiedad.dormitorios} | Baños: {propiedad.banos} | Orientacion: {propiedad.orientacion} | mascotas: {propiedad.mascotas} | MetrosEdificados: {propiedad.metrosEdificados}</p>
            </div>
        </div>
    ) : null
}

export default Propiedad
