import React from 'react'
import { Navbar,NavDropdown,Nav,Container,Toast,ToastContainer } from 'react-bootstrap';
import Icon from '@mdi/react'
import {mdiBellOutline,mdiAccount} from '@mdi/js'
import { obtenerNotificacionesAceptadaGeneral } from '../redux/ObtenerNotificacionesAceptadaGeneral';
import { obtenerNotificacionesMeGustaGeneral } from '../redux/ObtenerNotificacionesMeGustaGeneral';
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

const General = () => {

 const dispatch = useDispatch()
 const aceptada = useSelector(store => store.obtenerNotificacionesAceptada)
 const aceptadaGeneral = useSelector(store => store.obtenerNotificacionesAceptadaGeneral)
 const meGustaGeneral = useSelector(store => store.obtenerNotificacionesMeGustaGeneral)

  let [cantidadAceptada, setCantidadAceptada] = React.useState(null)
  let [cantidadMeGusta, setCantidadMeGusta] = React.useState(null)


  const [show, setShow] = React.useState(false);
  const [show2, setShow2] = React.useState(false);

  React.useEffect(()=>{
  
        dispatch(obtenerNotificacionesAceptadaGeneral(localStorage.getItem("id_usuario").replaceAll('"','')))
        dispatch(obtenerNotificacionesMeGustaGeneral(localStorage.getItem("id_usuario").replaceAll('"','')))
  }, [])

  React.useEffect(() => {

    setInterval(() => {

        dispatch(obtenerNotificacionesAceptadaGeneral(localStorage.getItem("id_usuario").replaceAll('"','')))
        dispatch(obtenerNotificacionesMeGustaGeneral(localStorage.getItem("id_usuario").replaceAll('"','')))
        
        
    },60000)

  }, []) 

  React.useEffect(() => {

    if(aceptadaGeneral.isFetching === false && aceptadaGeneral.results !== null){
    if(typeof(aceptadaGeneral.results.result.cantidad) === 'number' && aceptadaGeneral.status === 'ok' ){
    
        if(aceptadaGeneral.results.result.cantidad >= 0){
      
        setCantidadAceptada(aceptadaGeneral.results.result.cantidad)

     
        }
    }}

},[aceptadaGeneral])

React.useEffect(() => {

  if(meGustaGeneral.isFetching === false && meGustaGeneral.results !== null){
  if(typeof(meGustaGeneral.results.result.cantidad) === 'number' && meGustaGeneral.status === 'ok' ){

      if(meGustaGeneral.results.result.cantidad >= 0){
          setCantidadMeGusta(meGustaGeneral.results.result.cantidad)
          
      }
  }}

},[meGustaGeneral])




    React.useEffect(() => {
 
      if(typeof(cantidadAceptada) === 'number'){
      
      if(cantidadAceptada == localStorage.getItem('CantidadAceptada')){
        setShow(false)
     

      }else{
        if(cantidadAceptada < localStorage.getItem('CantidadAceptada')){
          setShow(false)
     
          localStorage.setItem('CantidadAceptada',cantidadAceptada)
        }else{

        setShow(true)
     
        localStorage.setItem('CantidadAceptada',cantidadAceptada)

        }
      }
    }else{

     setShow(false)
    }
    if(cantidadAceptada === 0){
      setShow(false)
    }
      
  },[cantidadAceptada]) 

  React.useEffect(() => {

    if(typeof(cantidadMeGusta) === 'number'){

    if(cantidadMeGusta == localStorage.getItem('CantidadMeGusta')){
      setShow2(false)
     
    }else{
      if(cantidadMeGusta < localStorage.getItem('CantidadMeGusta')){
        setShow2(false)
        localStorage.setItem('CantidadMeGusta',cantidadMeGusta)
      }else{
      setShow2(true)
      localStorage.setItem('CantidadMeGusta',cantidadMeGusta)

      }
    }
  }else{
    setShow2(false)
  }

  if(cantidadMeGusta === 0){
    setShow2(false)
  }
  
  },[cantidadMeGusta]) 

let auxIcon1 = []
let auxIcon2 = []
if(cantidadMeGusta>0 || localStorage.getItem('CantidadMeGusta')>0){
  auxIcon1.push(<Icon path={mdiBellOutline } color="#ffcc00" title="silverware" size={1} className="align-middle colorIconos" />)
}
if(cantidadAceptada>0 || localStorage.getItem('cantidadAceptada')>0){
  auxIcon2.push(<Icon path={mdiBellOutline } color="#ffcc00" title="silverware" size={1} className="align-middle colorIconos" />)
}

const history = useHistory()

const logOutClear = () =>{
  localStorage.clear()
  history.push('/')
  window.location.reload(true)

}

    return (
        
      <>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
         <Container>
         <Navbar.Brand href="/general">Alquilalo.uy</Navbar.Brand>
         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
         <Navbar.Collapse id="responsive-navbar-nav">
           <Nav className="me-auto">

             <NavDropdown /* title="Propietario" */ open={false} id="collasible-nav-dropdown" title={<React.Fragment>Propietario  {auxIcon1}</React.Fragment>}>
               <NavDropdown.Item href="/ingresarPropiedades">Ingresar propiedad</NavDropdown.Item>
               <NavDropdown.Divider />
               <NavDropdown.Item href="/misPropiedades">Mis propiedades</NavDropdown.Item>
             </NavDropdown>

             <NavDropdown /* title="Inquilino" */ id="collasible-nav-dropdown" title={<React.Fragment>Inquilino  {auxIcon2}</React.Fragment>}>
               <NavDropdown.Item href="/ingresarBusquedas">Ingresar busqueda</NavDropdown.Item>
               <NavDropdown.Divider />
               <NavDropdown.Item href="/misBusquedas">Mis busquedas</NavDropdown.Item>
             </NavDropdown>

           </Nav>

           <Nav>
              <NavDropdown title={<React.Fragment><Icon path={mdiAccount } title="silverware" size={1} className="align-middle colorIconos" />  {localStorage.getItem('name_usuario').replaceAll('"',"")}</React.Fragment>}>
                  <NavDropdown.Item onClick={()=>logOutClear()}>Logout</NavDropdown.Item>
              </NavDropdown>
           </Nav>

         </Navbar.Collapse>
         </Container>
        </Navbar>



        
        <ToastContainer className="p-3" position='bottom-end'>

        <Toast onClose={() => setShow(false)} show={show} delay={5000} autohide>
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">Match</strong>
                <small>en este momento</small>
              </Toast.Header>
              <Toast.Body>Tienes {cantidadAceptada} match</Toast.Body>
        </Toast>  

        <Toast onClose={() => setShow2(false)} show={show2} delay={5000} autohide>
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">Me gusta</strong>
                <small>en este momento</small>
              </Toast.Header>
              <Toast.Body className="me-auto">Tienes {cantidadMeGusta} me gusta</Toast.Body>
        </Toast>  


        </ToastContainer>
        </>
   
    )
}

export default General
