import React from 'react'
import { obtenerNotificacionesAceptadaBadge } from '../redux/ObtenerNotificacionesAceptadaBadge';
import { Badge } from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux'



const BadgeComponentMatch = (props) => {
    
    const dispatch = useDispatch()
    const aceptadaBadge = useSelector(store => store.obtenerNotificacionesAceptadaBadge)

    
    React.useEffect(() => {
    dispatch(obtenerNotificacionesAceptadaBadge(localStorage.getItem("id_usuario").replaceAll('"',''), props.idBusquedaDefault))
    
    },[])

    let [cantidadBadgeMatch,setCantidadBadgeMatch] = React.useState(null)
    React.useEffect(() => {
        if(aceptadaBadge.isFetching === false && aceptadaBadge.results !== null){
            if(aceptadaBadge.results !== null && aceptadaBadge.status === 'ok' ){
                if(/* aceptadaBadge.results.result.cantidad && */ aceptadaBadge.results.result.id_busqueda === props.idBusquedaDefault ){
        setCantidadBadgeMatch(aceptadaBadge.results.result.cantidad)}}}
    },[aceptadaBadge])


    return (
        <>
        {(cantidadBadgeMatch!== 0)?(
        <Badge bg='warning' text="dark">{cantidadBadgeMatch}</Badge>
        ):(null)}
        </>
    )
}

export default BadgeComponentMatch