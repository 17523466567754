import React from 'react'
import { Card,Button,Container,Row,Col,Table } from 'react-bootstrap';
import Icon from '@mdi/react'
import { mdiBedKing,mdiShower,mdiCurrencyUsd,mdiMagnify,mdiTransferRight,mdiTransferLeft } from '@mdi/js'
import {useDispatch, useSelector} from 'react-redux'
import {obtenerBusquedas, siguientePaginaBusquedas, anteriorPaginaBusquedas} from '../redux/BusquedasDucks'
import {obtenerPropiedadesPosibles} from '../redux/PropiedadesPosiblesDucks'
import {ingresarPropiedadesPosibles} from '../redux/IngresoPropiedadesPosiblesDucks'
import TabsInquilino from './TabsInquilino';
import General from './General';
import BadgeComponentMatch from './BadgeComponentMatch';
import Login3 from './Login3';

const MisBusquedas = () => {

//Dispatch es el que llama al accion
const dispatch = useDispatch()
const busquedas = useSelector(store => store.busquedas)//.results) 
const next = useSelector(store => store.busquedas.next) 
const previous = useSelector(store => store.busquedas.previous)

    React.useEffect(() => {
       const fetchData = () => {
           dispatch(obtenerBusquedas())
       }
       fetchData()
   },[dispatch]) 

   let [error_msg, setError_msg] = React.useState('') 


   React.useEffect(() => {

       if(busquedas.isFetching === false && busquedas.status === "error"){
           setError_msg(busquedas.results.error_msg)

       }
   },[busquedas])
   
   let msgError = []
   if(error_msg !== ''){
       msgError.push(<div className="alert alert-danger">{error_msg}</div>)
   }

   const [datos,setDatos] = React.useState(null)

   const propiedadesPosibles = (precioDesdeAlquiler,dormitorios,banos,orientacion,mascotas,idUsuario,precioHastaAlquiler,propiedadId) => {

       setDatos({
           precioDesdeAlquiler: precioDesdeAlquiler,
           dormitorios: dormitorios,
           banos: banos,
           orientacion: orientacion,
           mascotas: mascotas,
           idUsuario: idUsuario,
           precioHastaAlquiler: precioHastaAlquiler,
           propiedadId: propiedadId,
           idBusqueda: propiedadId
       })
       
       dispatch(obtenerPropiedadesPosibles(propiedadId,0,0,0,0,0))
       
       
       //dispatch(obtenerPropiedadesPosibles(propiedadId))

   }
   if(datos !== null){
       dispatch(ingresarPropiedadesPosibles(datos))
   }







    return (
        <>
        {(localStorage.getItem("token"))?(

        <div>
          {(datos === null) ? (
            <Container fluid>
            <General/>
                <Row className="justify-content-center p-1">
                    <Col md={6} xs={12} >
                        
                        <Card className="text-center borde mb-3 justify-content-center">
                            <Card.Header>
                            </Card.Header>
                            <Card.Body>
                                <Card.Title>Mis busquedas</Card.Title>

                                <Table striped bordered hover>
                                  <thead>
                                    <tr>
                                      <th>Id</th>
                                      <th><Icon path={mdiCurrencyUsd} title="silverware" size={1} className="align-middle colorIconos"/> </th>
                                      <th><Icon path={mdiBedKing} title="silverware" size={1} className="align-middle colorIconos"/></th>
                                      <th><Icon path={mdiShower} title="silverware" size={1} className="align-middle colorIconos"/></th>
                                      
                                    </tr> 
                                    <tr></tr>
                                  </thead>
                                  <tbody>
                                  {   busquedas.results !== null ? (
                                      busquedas.status === 'ok' &&
                                      busquedas.results.result.datos.map(item => (

                                        <tr key={item.propiedadId}>
                                          <td>
                                          {item.propiedadId}
                                          </td>
                                          <td>
                                          {item.precioDesdeAlquiler} -- {item.precioHastaAlquiler}
                                          </td>
                                          <td>
                                          {item.dormitorios} 
                                          </td>
                                          <td>
                                          {item.banos} 
                                          </td>
                                          <td className="p-1">

                                        <Row className="justify-content-md-center">
                                        <Col md="2">
                                          {(item.notificacionFlag === '1')?
                                          <Button variant="secondary" className="p-1" onClick={() => propiedadesPosibles(item.precioDesdeAlquiler,item.dormitorios,item.banos,item.orientacion,item.mascotas,item.idUsuario,item.precioHastaAlquiler,item.propiedadId)}>
                                            <Icon path={mdiMagnify  } title="silverware" size={1} className="align-middle colorIconos" color="#F5B041"/>
                                        </Button>
                                        :
                                        <Button variant="secondary" className="p-1" onClick={() => propiedadesPosibles(item.precioDesdeAlquiler,item.dormitorios,item.banos,item.orientacion,item.mascotas,item.idUsuario,item.precioHastaAlquiler,item.propiedadId)}>
                                        <Icon path={mdiMagnify  } title="silverware" size={1} className="align-middle colorIconos"/>
                                        </Button>}
                                        </Col>
                                         <Col md="2">
                                         <BadgeComponentMatch idBusquedaDefault={item.propiedadId}/> 
                                         </Col>
                                         </Row>
                                          </td>
                                        </tr>

                                      ))
                                  ):(
                                   
                                
                                     null
                                
                                   
                                  )}

                                  </tbody>

                                 

                                  <Row className="justify-content-center p-4">
                                  {previous !== null && next!== null ? (
                     
                                  (previous.result.previous!==0) ? (
                                
                                    <Col md={4} xs={12}>
                                            <Button variant="secondary">
                                                <Icon path={mdiTransferLeft } title="silverware" size={1} className="align-middle colorIconos" onClick={() => dispatch(anteriorPaginaBusquedas()) }/>
                                            </Button>
                                    </Col>

                                  ):null
                                  ):null}

                                  
                                  {/* previous !== null && */ next!== null ? (
                       
                                  (next.result.next) ? (
                                        <Col md={4} xs={12}>
                                            <Button variant="secondary">
                                                <Icon path={mdiTransferRight  } title="silverware" size={1} className="align-middle colorIconos" onClick={() => dispatch(siguientePaginaBusquedas()) }/>
                                            </Button>
                                        </Col>
                                  ):null
                                  ):null}     
                                       
                                  </Row>   

                                  
                                  
                                </Table>

                                {busquedas.results === null ? (
                                    <div className="card mt-4 text-center">
                                    <div className="card-body"> 
                                    <p>No tiene busquedas</p>
                                    </div>
                                    </div> 
                                ):(null)}



                            </Card.Body>
                            {msgError}
                        </Card>
                    </Col>
                
                </Row>
                </Container>

        ) :( 
        
            <TabsInquilino datos={datos}/>
      
        
        )}    

            
        </div>

        ):(<Login3/>)}
        </>

    )
}

export default MisBusquedas
