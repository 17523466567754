import axios from 'axios'
import {urlApi} from '../services/apirest'

//3-constantes
const dataInicial = {
    status: '',
    results: [],
    isFetching : false,
    next: null,
    previous: null
}


//Types

//ALL
const OBTENER_PROPIEDADES_EXITO = 'OBTENER_PROPIEDADES_EXITO'
const OBTENER_PROPIEDADES_ERROR = 'OBTENER_PROPIEDADES_ERROR'
const OBTENER_PROPIEDADES_ESPERANDO = 'OBTENER_PROPIEDADES_ESPERANDO'

//NEXT
const OBTENER_NEXT_PROPIEDADES_EXITO = 'OBTENER_NEXT_PROPIEDADES_EXITO'
const OBTENER_NEXT_PROPIEDADES_ERROR = 'OBTENER_NEXT_PROPIEDADES_ERROR'
const OBTENER_NEXT_PROPIEDADES_ESPERANDO = 'OBTENER_NEXT_PROPIEDADES_ESPERANDO'

//PREVIOUS
const OBTENER_PREVIOUS_PROPIEDADES_EXITO = 'OBTENER_PREVIOUS_PROPIEDADES_EXITO'
const OBTENER_PREVIOUS_PROPIEDADES_ERROR = 'OBTENER_PREVIOUS_PROPIEDADES_ERROR'
const OBTENER_PREVIOUS_PROPIEDADES_ESPERANDO = 'OBTENER_PREVIOUS_PROPIEDADES_ESPERANDO'

//ONE
const OBTENER_PROPIEDAD_EXITO = 'OBTENER_PROPIEDAD_EXITO'
const OBTENER_PROPIEDAD_ERROR = 'OBTENER_PROPIEDAD_ERROR'
const OBTENER_PROPIEDAD_ESPERANDO = 'OBTENER_PROPIEDAD_ESPERANDO'




//reducer
export default function propiedadesReducer(state = dataInicial, action){
    switch(action.type){

        case OBTENER_PROPIEDADES_ESPERANDO:
            return {
                ...state,
                isFetching: true
                
            }

        case OBTENER_PROPIEDADES_ERROR:
            return {...state, 
                isFetching: false,
                status: action.payload.status,
                results: action.payload.result
                
            }

        case OBTENER_PROPIEDADES_EXITO:
            return {
                ...state,
                isFetching: false,
                status: 'ok',
                results: action.payload.result.datos,
                next: action.payload.result.next,
                previous: action.payload.result.previous
                
            }

            case OBTENER_NEXT_PROPIEDADES_ESPERANDO:
                return {
                    ...state,
                    isFetching: true
                    
                }
    
            case OBTENER_NEXT_PROPIEDADES_ERROR:
                return {...state, 
                    isFetching: false,
                    status: action.payload.status,
                    results: action.payload.result
                    
                }
    
            case OBTENER_NEXT_PROPIEDADES_EXITO:
                return {
                    ...state,
                    isFetching: false,
                    status: 'ok',
                    results: action.payload.result.datos,
                    next: action.payload.result.next,
                    previous: action.payload.result.previous
                    
                }

            case OBTENER_PREVIOUS_PROPIEDADES_ESPERANDO:
                return {
                    ...state,
                    isFetching: true
                        
                }
        
            case OBTENER_PREVIOUS_PROPIEDADES_ERROR:
                return {...state, 
                    isFetching: false,
                    status: action.payload.status,
                    results: action.payload.result
                        
                }
        
            case OBTENER_PREVIOUS_PROPIEDADES_EXITO:
                return {
                    ...state,
                    isFetching: false,
                    status: 'ok',
                    results: action.payload.result.datos,
                    next: action.payload.result.next,
                    previous: action.payload.result.previous
                        
                }    
                
            case OBTENER_PROPIEDAD_ESPERANDO:
                return {
                    ...state,
                    isFetching: true
                            
                }
            
            case OBTENER_PROPIEDAD_ERROR:
                return {...state, 
                    isFetching: false,
                    status: action.payload.status,
                    results: action.payload.result
                            
                }
            
            case OBTENER_PROPIEDAD_EXITO:
                return {
                    ...state,
                    isFetching: false,
                    status: 'ok',
                    unaPropiedad: action.payload      
                 }
        
        default:
            return state
    }
}


//acciones
//con el dispatch activamos el reducer y con el getstate obtenemos la data inicial
export const obtenerPropiedades = () => async (dispatch) => {
    
    //Preguntamos si existe en el localstorage para no hacer peticiones a la api de gusto
    if(localStorage.getItem('page=1')){

        dispatch({
            type: OBTENER_PROPIEDADES_EXITO,
            //Devolvemos toda la data ahora
            payload: JSON.parse(localStorage.getItem('page=1'))
        })
        //ponemos un return para salir de la funcion, tambien se podria haber puesto un else del if
        return
    }

    const url = urlApi + 'propiedades'

    dispatch({
        type: OBTENER_PROPIEDADES_ESPERANDO,
    })

    try {  
        const res = await axios.get(url+'?page=1')

            dispatch({
                type: OBTENER_PROPIEDADES_EXITO,
                //Devolvemos toda la data ahora
                payload: res.data
            })
            
            //Guardar el resultado en un localstorage
            //se setea con una key el valor en string
            localStorage.setItem('page=1',JSON.stringify(res.data))


    }catch (error) {

        dispatch({
            type: OBTENER_PROPIEDADES_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }
}

export const siguientePaginaPropiedades = () => async (dispatch,getState) => {

    const next = getState().propiedades.next

    //Preguntamos si existe en el localstorage para no hacer peticiones a la api de gusto
    if(localStorage.getItem('page='+next)){
        dispatch({
            type: OBTENER_NEXT_PROPIEDADES_EXITO,
            //Devolvemos toda la data ahora
            payload: JSON.parse(localStorage.getItem('page='+next))
        })
        //ponemos un return para salir de la funcion, tambien se podria haber puesto un else del if
        return
    }

    const url = urlApi + 'propiedades'

    dispatch({
        type: OBTENER_NEXT_PROPIEDADES_ESPERANDO,
    })

    try {
        const res = await axios.get(url+'?page='+next)
        dispatch({
            type: OBTENER_NEXT_PROPIEDADES_EXITO,
            payload: res.data
        })
        //Guardar el resultado en un localstorage
        //se setea con una key el valor en string
        localStorage.setItem('page='+next,JSON.stringify(res.data))

    } catch (error) {
        dispatch({
            type: OBTENER_NEXT_PROPIEDADES_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }

}


export const anteriorPaginaPropiedades = () => async (dispatch,getState) => {

    const previous = getState().propiedades.previous

    //Preguntamos si existe en el localstorage para no hacer peticiones a la api de gusto
    if(localStorage.getItem('page='+previous)){
        dispatch({
            type: OBTENER_NEXT_PROPIEDADES_EXITO,
            //Devolvemos toda la data ahora
            payload: JSON.parse(localStorage.getItem('page='+previous))
        })
        //ponemos un return para salir de la funcion, tambien se podria haber puesto un else del if
        return
    }

    const url = urlApi + 'propiedades'

    dispatch({
        type: OBTENER_PREVIOUS_PROPIEDADES_ESPERANDO,
    })

    try {
        const res = await axios.get(url+'?page='+previous)
        dispatch({
            type: OBTENER_PREVIOUS_PROPIEDADES_EXITO,
            payload: res.data
        })
        //Guardar el resultado en un localstorage
        //se setea con una key el valor en string
        localStorage.setItem('page='+previous,JSON.stringify(res.data))

    } catch (error) {
        dispatch({
            type: OBTENER_PREVIOUS_PROPIEDADES_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }

}

export const obtenerPropiedad = (id = '190') => async (dispatch) => {

    const url = urlApi + 'propiedades'

    if(localStorage.getItem('propiedad='+id)){
        
        dispatch({
            type: OBTENER_PROPIEDAD_EXITO,
            //Devolvemos toda la data ahora
            payload: JSON.parse(localStorage.getItem('propiedad='+id))
        })
        //ponemos un return para salir de la funcion, tambien se podria haber puesto un else del if
        return
    }

    dispatch({
        type: OBTENER_PROPIEDAD_ESPERANDO,
    })

    try {
        const res = await axios.get(url+'?id='+id)

        dispatch({
            type: OBTENER_PROPIEDAD_EXITO,
            payload: {
                propiedadId: res.data[0].propiedadId,
                precioAlquiler: res.data[0].precioAlquiler,
                dormitorios: res.data[0].dormitorios,
                banos: res.data[0].banos,
                orientacion: res.data[0].orientacion,
                mascotas: res.data[0].mascotas,
                metrosEdificados: res.data[0].metrosEdificados,
                }
        })

        localStorage.setItem('propiedad='+id,JSON.stringify({
            propiedadId: res.data[0].propiedadId,
            precioAlquiler: res.data[0].precioAlquiler,
            dormitorios: res.data[0].dormitorios,
            banos: res.data[0].banos,
            orientacion: res.data[0].orientacion,
            mascotas: res.data[0].mascotas,
            metrosEdificados: res.data[0].metrosEdificados,
        }))

    } catch (error)  {

        dispatch({
            type: OBTENER_PROPIEDAD_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }

}