import axios from 'axios'
import {urlApi} from '../services/apirest'

//3-constantes
const dataInicial = {
    status: '',
    results: [],
    isFetching : false,
}


//Types

//MODIFICAR
const MODIFICAR_PROPIEDADES_POSIBLES_EXITO = 'MODIFICAR_PROPIEDADES_POSIBLES_EXITO'
const MODIFICAR_PROPIEDADES_POSIBLES_ERROR = 'MODIFICAR_PROPIEDADES_POSIBLES_ERROR'
const MODIFICAR_PROPIEDADES_POSIBLES_ESPERANDO = 'MODIFICAR_PROPIEDADES_POSIBLES_ESPERANDO'


//reducer
export default function propiedadesPosiblesModificarReducer(state = dataInicial, action){
    switch(action.type){

        case MODIFICAR_PROPIEDADES_POSIBLES_ESPERANDO:
            return {
                ...state,
                isFetching: true
                
            }

        case MODIFICAR_PROPIEDADES_POSIBLES_ERROR:
            return {...state, 
                isFetching: false,
                status: action.payload.status,
                results: action.payload.result
                
            }

        case MODIFICAR_PROPIEDADES_POSIBLES_EXITO:
            return {
                ...state,
                isFetching: false,
                status: 'ok',
                results: action.payload.result.datos,
                
            }

            default:
                return state
        
    
            }
        }


//acciones
//con el dispatch activamos el reducer y con el getstate obtenemos la data inicial
export const modificarPropiedadesPosibles = (id_inquilino,id_busqueda,id_propiedad,meGusta,noMeGusta,talVez,aceptada,noAceptada) => async (dispatch) => {
    
    const url = urlApi + 'propiedades'

    dispatch({
        type: MODIFICAR_PROPIEDADES_POSIBLES_ESPERANDO,
    })

    try {  
        const token = localStorage.getItem("token").toString().substring(1,33)
        if(id_inquilino === null){
        id_inquilino = localStorage.getItem("id_usuario").replaceAll('"','')
        }

        const res = await axios.put(url+'?id_inquilino='+id_inquilino+'&id_busqueda='+id_busqueda+'&id_propiedad='+id_propiedad+'&meGusta='+meGusta+'&noMeGusta='+noMeGusta+'&talVez='+talVez+'&aceptada='+aceptada+'&noAceptada='+noAceptada+'&token='+token)

            dispatch({
                type: MODIFICAR_PROPIEDADES_POSIBLES_EXITO,
                //Devolvemos toda la data ahora
                payload: res.data
            })
            
    }catch (error) {
        
        dispatch({
            type: MODIFICAR_PROPIEDADES_POSIBLES_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }
}
