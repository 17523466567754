import axios from 'axios'
import {urlApi} from '../services/apirest'

//3-constantes
const dataInicial = {
    status: '',
    results: [],
    isFetching : false,
    next: null,
    previous: null
}


//Types

//ALL
const OBTENER_PROPIEDADES_PROPIEATARIO_EXITO = 'OBTENER_PROPIEDADES_PROPIEATARIO_EXITO'
const OBTENER_PROPIEDADES_PROPIEATARIO_ERROR = 'OBTENER_PROPIEDADES_PROPIEATARIO_ERROR'
const OBTENER_PROPIEDADES_PROPIEATARIO_ESPERANDO = 'OBTENER_PROPIEDADES_PROPIEATARIO_ESPERANDO'

//NEXT
const OBTENER_NEXT_PROPIEDADES_PROPIEATARIO_EXITO = 'OBTENER_NEXT_PROPIEDADES_PROPIEATARIO_EXITO'
const OBTENER_NEXT_PROPIEDADES_PROPIEATARIO_ERROR = 'OBTENER_NEXT_PROPIEDADES_PROPIEATARIO_ERROR'
const OBTENER_NEXT_PROPIEDADES_PROPIEATARIO_ESPERANDO = 'OBTENER_NEXT_PROPIEDADES_PROPIEATARIO_ESPERANDO'

//PREVIOUS
const OBTENER_PREVIOUS_PROPIEDADES_PROPIEATARIO_EXITO = 'OBTENER_PREVIOUS_PROPIEDADES_PROPIEATARIO_EXITO'
const OBTENER_PREVIOUS_PROPIEDADES_PROPIEATARIO_ERROR = 'OBTENER_PREVIOUS_PROPIEDADES_PROPIEATARIO_ERROR'
const OBTENER_PREVIOUS_PROPIEDADES_PROPIEATARIO_ESPERANDO = 'OBTENER_PREVIOUS_PROPIEDADES_PROPIEATARIO_ESPERANDO'

//ONE
const OBTENER_PROPIEDAD_PROPIEATARIO_EXITO = 'OBTENER_PROPIEDAD_PROPIEATARIO_EXITO'
const OBTENER_PROPIEDAD_PROPIEATARIO_ERROR = 'OBTENER_PROPIEDAD_PROPIEATARIO_ERROR'
const OBTENER_PROPIEDAD_PROPIEATARIO_ESPERANDO = 'OBTENER_PROPIEDAD_PROPIEATARIO_ESPERANDO'


//reducer
export default function propiedadesPropietarioReducer(state = dataInicial, action){
    switch(action.type){

        case OBTENER_PROPIEDADES_PROPIEATARIO_ESPERANDO:
            return {
                ...state,
                isFetching: true
                
            }

        case OBTENER_PROPIEDADES_PROPIEATARIO_ERROR:
            return {...state, 
                isFetching: false,
                status: action.payload.status,
                results: action.payload.result
                
            }

        case OBTENER_PROPIEDADES_PROPIEATARIO_EXITO:
            return {
                ...state,
                isFetching: false,
                status: 'ok',
                results: action.payload,//.result.datos,
                next: action.payload,//.result.next,
                previous: action.payload,//.result.previous
                
            }

            case OBTENER_NEXT_PROPIEDADES_PROPIEATARIO_ESPERANDO:
                return {
                    ...state,
                    isFetching: true
                    
                }
    
            case OBTENER_NEXT_PROPIEDADES_PROPIEATARIO_ERROR:
                return {...state, 
                    isFetching: false,
                    status: action.payload.status,
                    results: action.payload.result
                    
                }
    
            case OBTENER_NEXT_PROPIEDADES_PROPIEATARIO_EXITO:
                return {
                    ...state,
                    isFetching: false,
                    status: 'ok',
                    results: action.payload,//.result.datos,
                    next: action.payload,//.result.next,
                    previous: action.payload,//.result.previous
                    
                }

            case OBTENER_PREVIOUS_PROPIEDADES_PROPIEATARIO_ESPERANDO:
                return {
                    ...state,
                    isFetching: true
                        
                }
        
            case OBTENER_PREVIOUS_PROPIEDADES_PROPIEATARIO_ERROR:
                return {...state, 
                    isFetching: false,
                    status: action.payload.status,
                    results: action.payload.result
                        
                }
        
            case OBTENER_PREVIOUS_PROPIEDADES_PROPIEATARIO_EXITO:
                return {
                    ...state,
                    isFetching: false,
                    status: 'ok',
                    results: action.payload,//.result.datos,
                    next: action.payload,//.result.next,
                    previous: action.payload,//.result.previous
                        
                }    
                
            case OBTENER_PROPIEDAD_PROPIEATARIO_ESPERANDO:
                return {
                    ...state,
                    isFetching: true
                            
                }
            
            case OBTENER_PROPIEDAD_PROPIEATARIO_ERROR:
                return {...state, 
                    isFetching: false,
                    status: action.payload.status,
                    results: action.payload.result
                            
                }
            
            case OBTENER_PROPIEDAD_PROPIEATARIO_EXITO:
                return {
                    ...state,
                    isFetching: false,
                    status: 'ok',
                    unaPropiedad: action.payload      
                 }
        
        default:
            return state
    }
}


//acciones
//con el dispatch activamos el reducer y con el getstate obtenemos la data inicial
export const obtenerPropiedadesPropietario = () => async (dispatch) => {
    

    const url = urlApi + 'propiedades'

    dispatch({
        type: OBTENER_PROPIEDADES_PROPIEATARIO_ESPERANDO,
    })

    try {  
        const idUsuario = localStorage.getItem("id_usuario").replaceAll('"','')
        const res = await axios.get(url+'?page=1&id_propietario='+idUsuario+'')

            dispatch({
                type: OBTENER_PROPIEDADES_PROPIEATARIO_EXITO,
                //Devolvemos toda la data ahora
                payload: res.data
            })
            


    }catch (error) {

        dispatch({
            type: OBTENER_PROPIEDADES_PROPIEATARIO_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }
}

export const siguientePaginaPropiedadesPropietario = () => async (dispatch,getState) => {

    const next = getState().propiedadesPropietario.results.result.next

    

    const url = urlApi + 'propiedades'

    dispatch({
        type: OBTENER_NEXT_PROPIEDADES_PROPIEATARIO_ESPERANDO,
    })

    try {
        const idUsuario = localStorage.getItem("id_usuario").replaceAll('"','')
        const res = await axios.get(url+'?page='+next+'&id_propietario='+idUsuario+'')
        dispatch({
            type: OBTENER_NEXT_PROPIEDADES_PROPIEATARIO_EXITO,
            payload: res.data
        })


    } catch (error) {
        dispatch({
            type: OBTENER_NEXT_PROPIEDADES_PROPIEATARIO_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }

}


export const anteriorPaginaPropiedadesPropietario = () => async (dispatch,getState) => {

    const previous = getState().propiedadesPropietario.results.result.previous

    const url = urlApi + 'propiedades'

    dispatch({
        type: OBTENER_PREVIOUS_PROPIEDADES_PROPIEATARIO_ESPERANDO,
    })

    try {
        const idUsuario = localStorage.getItem("id_usuario").replaceAll('"','')
        const res = await axios.get(url+'?page='+previous+'&id_propietario='+idUsuario+'')
        dispatch({
            type: OBTENER_PREVIOUS_PROPIEDADES_PROPIEATARIO_EXITO,
            payload: res.data
        })


    } catch (error) {
        dispatch({
            type: OBTENER_PREVIOUS_PROPIEDADES_PROPIEATARIO_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }

}

export const obtenerPropiedad = (id ) => async (dispatch) => {

    const url = urlApi + 'propiedades'


    dispatch({
        type: OBTENER_PROPIEDAD_PROPIEATARIO_ESPERANDO,
    })

    try {
        const res = await axios.get(url+'?id='+id)

        dispatch({
            type: OBTENER_PROPIEDAD_PROPIEATARIO_EXITO,
            payload: {
                propiedadId: res.data[0].propiedadId,
                precioAlquiler: res.data[0].precioAlquiler,
                dormitorios: res.data[0].dormitorios,
                banos: res.data[0].banos,
                orientacion: res.data[0].orientacion,
                mascotas: res.data[0].mascotas,
                metrosEdificados: res.data[0].metrosEdificados,
                }
        })


    } catch (error)  {

        dispatch({
            type: OBTENER_PROPIEDAD_PROPIEATARIO_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }

}