import React from 'react'
import { Link } from "react-router-dom";


const Menu = () => {

    const [ingresarPropiedad, setIngresarPropiedad] = React.useState(false)
    const [verPropiedades, setVerPropiedades] = React.useState(false)


    return (
    <>

        <div className="card mt-4 text-center">
            <div className="card-body">
                <Link to="/ver">
                    <button>Ver propiedades</button>
                </Link>
                <br></br>
                <br></br>
                <Link to="/ingresar">
                <button>Ingresar propiedad</button>
                </Link>
                <br></br>
                <br></br>
                <Link to="/buscar">
                <button>Ingresar busqueda de propiedad</button>
                </Link>
                <br></br>
                <br></br>
                <Link to="/posibles">
                <button>Ver posibles propiedades</button>
                </Link>
                <br></br>
                <br></br>
                <Link to="/busquedas">
                <button>Busquedas mias</button>
                </Link>
                <br></br>
                <br></br>
                <Link to="/propiedadesPropietario">
                <button>Propiedades propietario</button>
                </Link>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <Link to="/clasificacion">
                <button>Clasificacion</button>
                </Link>
                <br></br>
                <br></br>
                <Link to="/ingresarBusquedas">
                <button>Ingresar busquedas</button>
                </Link>
                
                <br></br>
                <br></br>
                <Link to="/misBusquedas">
                <button>Mis busquedas</button>
                </Link>

                <br></br>
                <br></br>
                <Link to="/ingresarPropiedades">
                <button>Ingresar propiedades</button>
                </Link>
                <br></br>
                <br></br>
                <Link to="/misPropiedades">
                <button>Mis propiedades</button>
                </Link>
                
                
            </div>
        </div>
        
     

    </>
    )
}

export default Menu
