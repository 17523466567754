import React from 'react'
import { Card,Button,Container,Row,Col,Form } from 'react-bootstrap';
import Icon from '@mdi/react'
import { mdiBedKing,mdiShower,mdiCurrencyUsd,mdiCompassOutline,mdiDog,mdiRulerSquare } from '@mdi/js'
import { useDispatch, useSelector } from 'react-redux'
import {ingresarPropiedad, resetDatos} from '../redux/IngresoPropiedadDucks'
import {ingresarPropiedadesPosibles} from '../redux/IngresoPropiedadesPosiblesDucks'
import General from './General';
import Login3 from './Login3';

const IngresarPropiedades = (props) => {

    const [precioDesdeAlquiler, setPrecioDesdeAlquiler] = React.useState('')
    const [precioHastaAlquiler, setPrecioHastaAlquiler] = React.useState('')
    const [dormitorios, setDormitorios] = React.useState('')
    const [banos, setBanos] = React.useState('')
    const [orientacion, setOrientacion] = React.useState('')
    const [mascotas, setMascotas] = React.useState('')
    const [metrosEdificados, setMetrosEdificados] = React.useState('')
    const [tipoPropietario, setTipoPropietario] = React.useState(props.tipoPropietario)
    const [tipoInquilino, setTipoInquilino] = React.useState(props.tipoInquilino)
    

    const dispatch = useDispatch();
    

    const guardarPropiedad = (e) => {

        e.preventDefault();

  
        let datos = {
            tipoPropietario: tipoPropietario,
            tipoInquilino: tipoInquilino,
            precioDesdeAlquiler: precioDesdeAlquiler,
            precioHastaAlquiler: precioHastaAlquiler,
            dormitorios: dormitorios,
            banos: banos,
            orientacion: orientacion,
            mascotas: mascotas,
            metrosEdificados: metrosEdificados,
            token: localStorage.getItem('token').toString().substring(1,33),
            idUsuario: localStorage.getItem('id_usuario').match(/(\d+)/g)[0]
        }


        dispatch(ingresarPropiedad(datos))

        

        e.target.reset()

        datos = {
            tipoPropietario: false,
            tipoInquilino: false,
            precioDesdeAlquiler: '',
            precioHastaAlquiler: '',
            dormitorios: '',
            banos: '',
            orientacion: '',
            mascotas: '',
            metrosEdificados: '',
            token: '',
            idUsuario: ''
        }
        }
 
        let resultadoIngresoPropiedad = useSelector((store) => store.ingresarPropiedad)


        


        let resultadoIngresoPropiedadPosible = useSelector((store) => store.ingresarPropiedadesPosibles)



        let [error_msg, setError_msg] = React.useState('') 
        let [ok_msg, setOk_msg] = React.useState('')
        let [cargando_msg, setCargando_msg] = React.useState('')
        const msgOk = React.useRef(null)
        const msgError = React.useRef(null)
        const msgCargando = React.useRef(null)


        React.useEffect(() => {

           if(resultadoIngresoPropiedad.isFetching === true) {
                setCargando_msg('Cargando')
                if(cargando_msg === ''){
                    msgCargando.current.style.display = "block"
                    
                    }else{
                        msgCargando.current.style.display = "none"
                        setCargando_msg()
                }

            }else{
                
                if(resultadoIngresoPropiedad.status === "error"){
                    msgCargando.current.style.display = "none"
                    setCargando_msg('')
                    setError_msg(resultadoIngresoPropiedad.results.error_msg)
                    if(error_msg === ''){
                        msgError.current.style.display = "block"
                        
                        }else{
                        msgError.current.style.display = "none"
                        setError_msg()
                    }
                    
                }
    
                if(resultadoIngresoPropiedad.status === "ok" && resultadoIngresoPropiedad.results !== null){
                    msgCargando.current.style.display = "none"
                    setCargando_msg('')
                    setOk_msg('La propiedad se a ingresado correctamente '+resultadoIngresoPropiedad.results)

                    if(tipoInquilino === true){
                        guardarPropiedadesPosibles()
                    }

                    if(ok_msg === ''){
                    msgOk.current.style.display = "block"
                    
                    }else{
                    msgOk.current.style.display = "none"
                    setOk_msg()
                    }

                    //Seteo a cero
                    setPrecioDesdeAlquiler('')
                    setPrecioHastaAlquiler('')
                    setDormitorios('')
                    setBanos('')
                    setOrientacion('')
                    setMascotas('')
                    setMetrosEdificados('')
                    setTipoPropietario(false)
                    setTipoInquilino(false)
                       
                }
            }  

        }, [resultadoIngresoPropiedad])     


        const guardarPropiedadesPosibles = () => {

            let datos2 = {
                tipoPropietario: tipoPropietario,
                tipoInquilino: tipoInquilino,
                precioDesdeAlquiler: precioDesdeAlquiler,
                precioHastaAlquiler: precioDesdeAlquiler,
                dormitorios: dormitorios,
                banos: banos,
                orientacion: orientacion,
                mascotas: mascotas,
                metrosEdificados: metrosEdificados,
                token: localStorage.getItem('token').toString().substring(1,33),
                idUsuario: localStorage.getItem('id_usuario').match(/(\d+)/g)[0],
                idBusqueda: resultadoIngresoPropiedad.results
            }

            dispatch(ingresarPropiedadesPosibles(datos2))

        datos2 = {
            tipoPropietario: false,
            tipoInquilino: false,
            precioDesdeAlquiler: '',
            precioHastaAlquiler: '',
            dormitorios: '',
            banos: '',
            orientacion: '',
            mascotas: '',
            metrosEdificados: '',
            token: '',
            idUsuario: '',
            idBusqueda: ''
        }

        }

        
         const volver = () => {
            dispatch(resetDatos())
            setTipoPropietario(false)
            setTipoInquilino(false)
        } 






    return (
        <>
        {(localStorage.getItem("token"))?(

        <Container fluid>
        <General/>
        <Row className="justify-content-center p-1">
                    <Col md={6} xs={12} >
                        
                        <Card className="text-center borde mb-3 justify-content-center">
                            <Card.Header>
                            </Card.Header>
                            <Card.Body>
                                <Card.Title>Ingresar propiedad</Card.Title>

                              <Form onSubmit={guardarPropiedad}>
                              <Row>
                                <Col md={4}>
                                <Row className="justify-content-center" xs={1}>
                                  <Col md={1} xs={1}>
                                      <Form.Group>
                                      <Icon path={mdiCurrencyUsd} title="silverware" size={1}  className="align-middle mb-3"/>
                                      </Form.Group>
                                      <br />
                                      <Form.Group>
                                      <Icon path={mdiBedKing} title="silverware" size={1}  className="align-middle mb-1"/>
                                      </Form.Group>
                                      <br />
                                      <Form.Group>
                                      <Icon path={mdiCompassOutline} title="silverware" size={1}  className="align-middle mb-1"/>
                                      </Form.Group>
                                  </Col>

                                  <Col md={9} xs={9}>

                                    <Form.Control placeholder="Precio mensual" className="mb-3" onChange={e=>setPrecioDesdeAlquiler(e.target.value)}/>
                                   
                                    <Form.Control required as="select" className="mb-3" onChange={e=>setDormitorios(e.target.value)}>
                                            <option value="">Dormitorios...</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                    </Form.Control>
                                   
                                    <Form.Control required as="select" className="mb-3" onChange={e=>setOrientacion(e.target.value)}>
                                            <option value="">Orientacion...</option>
                                            <option>Norte</option>
                                            <option>Sur</option>
                                            <option>Este</option>
                                            <option>Oeste</option>
                                  </Form.Control>
                                   
                                  </Col>
                                  </Row>
                                </Col>


                                <Col md={4}>
                                <Row className="justify-content-center" xs={1}>
                                  <Col md={1} xs={1}>
                                      {/* <Form.Group>
                                      <Icon path=mdiCurrencyUsd title="silverware" size={1}  className="align-middle mb-3"/>
                                      </Form.Group>
                                      <br /> */}
                                      <Form.Group>
                                      <Icon path={mdiShower} title="silverware" size={1}  className="align-middle mb-1"/>
                                      </Form.Group>
                                      <br />
                                      <Form.Group>
                                      <Icon path={mdiDog} title="silverware" size={1}  className="align-middle mb-1"/>
                                      </Form.Group>
                                  </Col>

                                  <Col md={9} xs={9}>

                                    {/* <Form.Control placeholder="Hasta" className="mb-3" onChange={e=>setPrecioHastaAlquiler(e.target.value)}/> */}
                                    
                                    <Form.Control required as="select" className="mb-3" onChange={e=>setBanos(e.target.value)}>
                                            <option value="">Baños...</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                    </Form.Control>
                                    
                                    <Form.Control required as="select" className="mb-3" onChange={e=>setMascotas(e.target.value)}>
                                            <option value="">Aceptan...</option>
                                            <option >Si</option>
                                            <option >No</option>
                                    </Form.Control>
                                    
                                  </Col>
                                  </Row>
                                </Col>


                                <Col md={4}>
                                <Row className="justify-content-center" xs={1}>
                                  <Col md={1} xs={1}>
                                      <Form.Group>
                                      <Icon path={mdiRulerSquare} title="silverware" size={1}  className="align-middle mb-3"/>
                                      </Form.Group>
                                      <br />
                                  </Col>

                                  <Col md={9} xs={9}>

                                  <Form.Control placeholder="m² edificados" className="mb-3" onChange={e=>setMetrosEdificados(e.target.value)}/>

                                  </Col>
                                </Row>
                                </Col>
                               
                                    

                              </Row>

                              <Row className="justify-content-center p-4">
                                  <Col md={4}>
                                      <Button variant="secondary" size="mg" block type="submit">
                                          Ingresar
                                      </Button>
                                  </Col>

                              </Row>
                                  
                            </Form>

                            <div className="alert alert-danger" ref={msgError} style={{display:'none'}}>{error_msg}</div>
                            <div className="alert alert-success" ref={msgOk} style={{display:'none'}}>{ok_msg}</div>
                            <div className="alert alert-secondary" ref={msgCargando} style={{display:'none'}}>{cargando_msg}</div>
                        
                        </Card.Body>
                            
                        </Card>
                    </Col>
                
                </Row>
                </Container>

                ):(<Login3/>)}
                </>
    )
}

export default IngresarPropiedades
