import React from 'react'
import { Card,Row,Col,Container } from 'react-bootstrap';
import General from './General';
import Login3 from './Login3';

const TipoUsuario = () => {
    return (

      <>
      {(localStorage.getItem("token"))?(
        <Container fluid>
        <General/>
        <Row className="justify-content-center p-1">
            <Col md={4} xs={12}>
                <Card
                  bg="secondary"
                  text='white'
                  /* style={{ width: '18rem' }} */
                  className="mb-2"
                >
                  <Card.Header>Alquilalo.uy</Card.Header>
                  <Card.Body>
                    <Card.Title>Bienvenido</Card.Title>
                    <Card.Text>
                      Puede navegar como propietario o inquilino. 
                      El menu lo dirigue a todas las opciones posibles.
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Col>
            </Row>
        </Container>

  ):(<Login3/>)}
  </>

    )
}

export default TipoUsuario
