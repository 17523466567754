import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {obtenerPropiedadesTalVez, siguientePaginaPropiedadesTalVez, anteriorPaginaPropiedadesTalVez} from '../redux/PropiedadesTalVezDucks'
import {ingresarPropiedadesPosibles} from '../redux/IngresoPropiedadesPosiblesDucks'
import {modificarPropiedadesPosibles} from '../redux/ModificarPropiedadPosibleDucks'
import { ingresarNotificacion } from '../redux/NotificacionesDucks'

const TalVez = (props) => {

 //Dispatch es el que llama al accion
 const dispatch = useDispatch()
 const propiedades = useSelector(store => store.propiedadesTalvez)//.results) 
 const next = useSelector(store => store.propiedadesTalvez.next) 
 const previous = useSelector(store => store.propiedadesTalvez.previous)
 const ingresoPropiedadesPosibles = useSelector(store => store.ingresarPropiedadesPosibles)

    let [error_msg, setError_msg] = React.useState('') 


    React.useEffect(() => {

        if(propiedades.isFetching === false && propiedades.status === "error"){
            setError_msg(propiedades.results.error_msg)
        }

    },[propiedades])

    React.useEffect(() => {
        if(ingresoPropiedadesPosibles.isFetching === false && ingresoPropiedadesPosibles.results !== null){
            //Hay un error de nombres, deberia ser busquedasId en vex de propiedadId
            dispatch(obtenerPropiedadesTalVez(props.datos.propiedadId,0,0,1,0,0))
        }

    },[ingresoPropiedadesPosibles])

    
    
    let msgError = []
    if(error_msg !== ''){
        msgError.push(<div className="alert alert-danger">{error_msg}</div>)
    }



const meGusta = (id_busqueda,id_propiedad,propietarioId) => {
    dispatch(modificarPropiedadesPosibles(null,id_busqueda,id_propiedad,1,0,0,0,0))
    dispatch(ingresarPropiedadesPosibles(props.datos))

    let datosNotificacion = {
        user1 : localStorage.getItem("id_usuario").replaceAll('"',''),
        user2: propietarioId,
        tipo: 'me gusta',
        leido: 0,
        propiedadId: id_propiedad
    }

    dispatch(ingresarNotificacion(datosNotificacion))
}

const noMeGusta = (id_busqueda,id_propiedad) => {
    dispatch(modificarPropiedadesPosibles(null,id_busqueda,id_propiedad,0,1,0,0,0))
    dispatch(ingresarPropiedadesPosibles(props.datos))
}
    


    return (
        <>
        {(propiedades.isFetching === true && propiedades.status === '') ? (<h1>cargando</h1>) :( 
        <>
        <div className="row">
            
            <div className="col-md-10">
            
                <h3>Tal vez</h3>

                {msgError}
               
                
                
                <ul className="list-group mt-3">
                    
                        {             propiedades.results !== null ? (
                                      propiedades.status === 'ok'  &&
                                      propiedades.results.result.datos.map(item => (

                                        <li key={item.id_propiedad} className="list-group-item text-uppercase">
                                            {item.id_busqueda} --{item.id_propiedad} -- {item.id_inquilino} -- {item.propietarioId} -- {item.meGusta} -- {item.noMeGusta} -- {item.talVez} -- {item.aceptada} -- {item.noAceptada}
                                            <button 
                                            className="btn btn-dark btn-sm float-right"
                                            >
                                                Info
                                            </button>
                                            
                                            <button 
                                            className="btn btn-success btn-sm float-right"
                                            onClick={() => meGusta(item.id_busqueda,item.id_propiedad,item.propietarioId) }
                                            >
                                                ME GUSTA
                                            </button>
                                            
                                            
                                            <button 
                                            className="btn btn-danger btn-sm float-right"
                                            onClick={() => noMeGusta(item.id_busqueda,item.id_propiedad) }
                                            >
                                                NO ME GUSTA
                                            </button>


                                        </li>
                                    ))
                                    ):(
                                        <p>No tiene ninguno</p>
                                    ) 
                        }
                    </ul>
                

                    {previous !== null && next!== null ? (
                     
                     (previous.result.previous!==0) ? (
                     <button onClick={() => dispatch(anteriorPaginaPropiedadesTalVez(props.datos.propiedadId,0,0,1,0,0)) } className="btn btn-dark">Anterior</button>
                     ):null
                     ):null}
 
                     {/* previous !== null && */ next!== null ? (
                       
                     (next.result.next) ? (
                     <button onClick={() => dispatch(siguientePaginaPropiedadesTalVez(props.datos.propiedadId,0,0,1,0,0)) } className="btn btn-dark">Siguiente</button>
                     ):null
                     ):null}

        
        
            </div>


{/*         <div className="col-md-6">
                <h3>Detalle Propiedad</h3>
                <Propiedad/>
        </div> */}
        </div>
        
        </>
        )}
        </>
    )
}

export default TalVez