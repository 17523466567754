import React from 'react'
import {Link} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ingresarUsuario, resetDatos } from '../redux/IngresoUsuarioDucks'
import { Navbar,Card,Button,Container,Row,Col,Form } from 'react-bootstrap';
import { mdiLock ,mdiAt ,mdiAccount ,mdiCellphoneBasic} from '@mdi/js'
import Icon from '@mdi/react'
import {useHistory} from 'react-router-dom'

const Registrar = () => {

    const [email, setEmail] = React.useState('')
    const [nombre, setNombre] = React.useState('')
    const [celular, setCelular] = React.useState('')
    const [password, setPassword] = React.useState('')

    React.useEffect(() => {
        localStorage.clear()
        },[]) 
        
    const dispatch = useDispatch();

    const guardarUsuario = (e) => {

        e.preventDefault();

        let datos = {
            email: email,
            nombre: nombre,
            celular: celular,
            password: password
        }

       dispatch(ingresarUsuario(datos))

        e.target.reset()

        datos = {
            email: '',
            nombre: '',
            celular: '',
            password: ''
        }
        }

        let resultadoIngresoUsuario = useSelector((store) => store.ingresarUsuario)

        let [error_msg, setError_msg] = React.useState('') 
        let [ok_msg, setOk_msg] = React.useState('')
        let [cargando_msg, setCargando_msg] = React.useState('')
        const msgOk = React.useRef(null)
        const msgError = React.useRef(null)
        const msgCargando = React.useRef(null)

        React.useEffect(() => {

            if(resultadoIngresoUsuario.isFetching === true) {
                 setCargando_msg('Cargando')
                 if(cargando_msg === ''){
                     msgCargando.current.style.display = "block"
                     
                     }else{
                         msgCargando.current.style.display = "none"
                         setCargando_msg()
                 }
 
             }else{
                 
                 if(resultadoIngresoUsuario.status === "error"){
                     msgCargando.current.style.display = "none"
                     setCargando_msg('')
                     setError_msg(resultadoIngresoUsuario.results.error_msg)
                     if(error_msg === ''){
                         msgError.current.style.display = "block"
                         
                         }else{
                         msgError.current.style.display = "none"
                         setError_msg()
                     }
                     
                 }
     
                 if(resultadoIngresoUsuario.status === "ok" && resultadoIngresoUsuario.results !== null){
                     msgCargando.current.style.display = "none"
                     setCargando_msg('')
                     
                     setOk_msg('El usuario se a ingresado correctamente '+resultadoIngresoUsuario.results)
 
                     if(ok_msg === ''){
                     msgOk.current.style.display = "block"
                     
                     }else{
                     msgOk.current.style.display = "none"
                     setOk_msg()
                     }
                     
                     //Seteo a cero
                     setEmail('')
                     setNombre('')
                     setCelular('')
                     setPassword('')
                        
                 }
             }  
 
         }, [resultadoIngresoUsuario])     
 
         
         const history = useHistory()
          const volver = () => {
             dispatch(resetDatos())
             history.push('/')
             window.location.reload(true)
         } 


    return (
<>
        <Container fluid>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
         <  Container>
                <Navbar.Brand >Alquilalo.uy</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                </Navbar.Collapse>
         </Container>
        </Navbar> 

        <Row className="justify-content-center p-1">
            <Col md={6} xs={12}>
                <Card className="text-center borde">
                <Card.Body>
                <Card.Title>Iniciar secion</Card.Title>

                <Form onSubmit={guardarUsuario}>
                              <Row className="justify-content-center p-4">
                                <Col md={6}>
                                <Row className="justify-content-center" xs={1}>
                                  <Col md={1} xs={1}>
                                      <Form.Group>
                                      <Icon path={mdiAt } title="silverware" size={1}  className="align-middle mb-3"/>
                                      </Form.Group>
                                      <br />
                                      <Form.Group>
                                      <Icon path={mdiAccount } title="silverware" size={1}  className="align-middle mb-1"/>
                                      </Form.Group>
                                      <br />
                                      <Form.Group>
                                      <Icon path={mdiCellphoneBasic} title="silverware" size={1}  className="align-middle mb-4"/>
                                      </Form.Group>
                                      <Form.Group>
                                      <Icon path={mdiLock } title="silverware" size={1}  className="align-middle mb-3"/>
                                      </Form.Group>
                                </Col>
                                <Col md={9} xs={9}>

                                    <Form.Control placeholder="email" className="mb-3" onChange={e=>setEmail(e.target.value)}/>
                                    <Form.Control placeholder="nombre" className="mb-3" onChange={e=>setNombre(e.target.value)}/>
                                    <Form.Control placeholder="celular" className="mb-3" onChange={e=>setCelular(e.target.value)}/>
                                    <Form.Control placeholder="password" type="password" className="mb-3" onChange={e=>setPassword(e.target.value)}/>
                                    </Col>
                                  </Row>
                                </Col>
                                </Row>

                                <Row className="justify-content-center p-1">
                                  <Col md={4}>
                                      <Button variant="secondary" size="mg" block type="submit">
                                          Registrar
                                      </Button>
                                  </Col>
                                </Row>

                                <Row className="justify-content-center p-1">
                                  <Col md={4}>
                                  <Link /* to = {'/'} */ onClick={volver} >Volver</Link>
                                  </Col>
                                </Row>

                                <div className="alert alert-danger" ref={msgError} style={{display:'none'}}>{error_msg}</div>
                                <div className="alert alert-success" ref={msgOk} style={{display:'none'}}>{ok_msg}</div>
                                <div className="alert alert-secondary" ref={msgCargando} style={{display:'none'}}>{cargando_msg}</div>

                            </Form>
                </Card.Body>
                </Card>
            </Col>
        </Row>
        </Container>
        
        </>   
    
    )
}

export default Registrar
