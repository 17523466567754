import React from 'react'
import { Card,Button,Row,Col,Carousel,ListGroup,ListGroupItem } from 'react-bootstrap';
import PRUEBA2 from '../img/PRUEBA2.jpg';
import Icon from '@mdi/react'
import { mdiBedKing,mdiShower,mdiCurrencyUsd,mdiCompassOutline,mdiDog,mdiRulerSquare,mdiThumbUpOutline,mdiThumbDownOutline,mdiTransferLeft,mdiTransferRight    } from '@mdi/js'
import {useDispatch, useSelector} from 'react-redux'
import {obtenerPropiedadesTalVez, siguientePaginaPropiedadesTalVez, anteriorPaginaPropiedadesTalVez} from '../redux/PropiedadesTalVezDucks'
import {ingresarPropiedadesPosibles} from '../redux/IngresoPropiedadesPosiblesDucks'
import {modificarPropiedadesPosibles} from '../redux/ModificarPropiedadPosibleDucks'
import { ingresarNotificacion } from '../redux/NotificacionesDucks'
import { obtenerPropiedad } from '../redux/ObtenerUnaPropiedadDucks';
import { modificarPropiedadesNotificacion } from '../redux/ModificarPropiedadesFlagNotificacion';

const TabTalVez = (props) => {

//Dispatch es el que llama al accion
const dispatch = useDispatch()
const propiedades = useSelector(store => store.propiedadesTalvez)//.results) 
const next = useSelector(store => store.propiedadesTalvez.next) 
const previous = useSelector(store => store.propiedadesTalvez.previous)
const ingresoPropiedadesPosibles = useSelector(store => store.ingresarPropiedadesPosibles)

   let [error_msg, setError_msg] = React.useState('') 


   React.useEffect(() => {
       
       if(propiedades.isFetching === false && propiedades.status === "error"){
           setError_msg(propiedades.results.error_msg)
       }

   },[propiedades])

   React.useEffect(() => {
       if(ingresoPropiedadesPosibles.isFetching === false && ingresoPropiedadesPosibles.results !== null){
           //Hay un error de nombres, deberia ser busquedasId en vex de propiedadId
           dispatch(obtenerPropiedadesTalVez(props.datos.propiedadId,0,0,1,0,0))
       }

   },[ingresoPropiedadesPosibles])

   
   
   let msgError = []
   if(error_msg !== ''){
       msgError.push(<div className="alert alert-danger">{error_msg}</div>)
   }



const meGusta = (id_busqueda,id_propiedad,propietarioId) => {
   dispatch(modificarPropiedadesPosibles(null,id_busqueda,id_propiedad,1,0,0,0,0))
   dispatch(ingresarPropiedadesPosibles(props.datos))

   let datosNotificacion = {
       user1 : localStorage.getItem("id_usuario").replaceAll('"',''),
       user2: propietarioId,
       tipo: 'me gusta',
       leido: 0,
       propiedadId: id_propiedad,
       id_busqueda: id_busqueda
   }

   dispatch(ingresarNotificacion(datosNotificacion))
   dispatch(modificarPropiedadesNotificacion(propiedades.results.result.datos[0].id_propiedad,1))
}

const noMeGusta = (id_busqueda,id_propiedad) => {
   dispatch(modificarPropiedadesPosibles(null,id_busqueda,id_propiedad,0,1,0,0,0))
   dispatch(ingresarPropiedadesPosibles(props.datos))
}

const propiedad = useSelector(store => store.propiedades.unaPropiedad)
React.useEffect(() => {
    if(propiedades.results !== null){
    if(propiedades.results.result){

    dispatch(obtenerPropiedad(propiedades.results.result.datos[0].id_propiedad))
        }}
}, [propiedades.results])

    return (
        <>
        {(propiedades.isFetching === true && propiedades.status === '') ? (<h1>cargando</h1>) :( 
        <>
        {       propiedades.results !== null ? (
                propiedades.status === 'ok'  &&
                propiedades.results.result.datos.map(item => (

        <Card key={item.id_propiedad} className="text-center borde">
                        <Card.Header>
                        
                        </Card.Header>
                        <Card.Body>
                        <Card.Title>Busqueda id: {props.datos.idBusqueda} / Id prop:{item.id_propiedad}</Card.Title>
                        {propiedad !== undefined ? ( 
                          <>
                                <Carousel controls={false}>
                                    <Carousel.Item>
                                      <img
                                        className="d-block w-100"
                                        src={PRUEBA2}
                                        alt="First slide"
                                      />
                                      
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={PRUEBA2}
                                        alt="Second slide"
                                      />


                                    </Carousel.Item>
                                    
                                </Carousel>
                            {/* <Card.Text>
                              With supporting text below as a natural lead-in to additional content.
                            </Card.Text> */}
                            
                              
                            <ListGroup className="list-group-flush">
                                <ListGroupItem>
                                    <Row className="justify-content-center p-4">
                                    <Col md={4} xs={12}>
                                            <Icon path={mdiCurrencyUsd} title="silverware" size={1} className="align-middle colorIconos p-0.8"/> 
                                            {propiedad.precioDesdeAlquiler}
                                    </Col>
                                    <Col md={4} xs={12}>
                                            <Icon path={mdiBedKing} title="silverware" size={1} className="align-middle colorIconos p-0.8"/>
                                            {propiedad.dormitorios}
                                    </Col>
                                    <Col md={4} xs={12}>
                                            <Icon path={mdiShower} title="silverware" size={1} className="align-middle colorIconos p-0.8"/>
                                            {propiedad.banos}
                                    </Col>
                                    </Row>
                              </ListGroupItem>
                              <ListGroupItem>
                                    <Row className="justify-content-center p-4">
                                    <Col md={4} xs={12}>
                                            <Icon path={mdiDog} title="silverware" size={1} className="align-middle colorIconos p-0.8"/> 
                                            {propiedad.mascotas}
                                    </Col>
                                    <Col md={4} xs={12}>
                                            <Icon path={mdiCompassOutline} title="silverware" size={1} className="align-middle colorIconos p-0.8"/>
                                            {propiedad.orientacion}
                                    </Col>
                                    <Col md={4} xs={12}>
                                            <Icon path={mdiRulerSquare} title="silverware" size={1} className="align-middle colorIconos p-0.8"/>
                                            {propiedad.metrosEdificados}
                                    </Col>
                                    </Row>
                              </ListGroupItem>
                            </ListGroup>
                            <Card.Footer className="colorH3">
                            <Row className="justify-content-center p-4">
                                
                                {previous !== null && next!== null ? (
                     
                                (previous.result.previous!==0) ? (
                                <Col md={3} xs={12}>
                                    <Button variant="secondary" onClick={() => dispatch(anteriorPaginaPropiedadesTalVez(props.datos.propiedadId,0,0,1,0,0)) }>
                                        <Icon path={mdiTransferLeft } title="silverware" size={1} className="align-middle colorIconos"/>
                                    </Button>
                                </Col>
                                 ):null
                                 ):null}
                                
                                <Col md={3} xs={12}>
                                    <Button variant="danger" onClick={() => noMeGusta(item.id_busqueda,item.id_propiedad) }>
                                        <Icon path={mdiThumbDownOutline} title="silverware" size={1} className="align-middle colorIconos"/>
                                    </Button>
                                </Col>
                                

                                <Col md={3} xs={12}>
                                    <Button variant="success" onClick={() => meGusta(item.id_busqueda,item.id_propiedad,item.propietarioId) }>
                                        <Icon path={mdiThumbUpOutline} title="silverware" size={1} className="align-middle colorIconos"/>
                                    </Button>
                                </Col>

                                {/* previous !== null && */ next!== null ? (
                      
                                (next.result.next) ? (
                                <Col md={3} xs={12}>
                                    <Button variant="secondary" onClick={() => dispatch(siguientePaginaPropiedadesTalVez(props.datos.propiedadId,0,0,1,0,0)) }>
                                        <Icon path={mdiTransferRight  } title="silverware" size={1} className="align-middle colorIconos"/>
                                    </Button>
                                </Col>
                                ):null
                                ):null}
                                
                               
                            </Row>  
                            </Card.Footer>  
                            </>
                        ):(
                            <Card className="text-center borde">
                                <Card.Header>

                                </Card.Header>
                                <Card.Body>
                                <Card.Title>No hay registros</Card.Title>
                                </Card.Body>
                            </Card>
                        )} 
                        </Card.Body>
                        </Card>

                ))
                ):(
                    <Card className="text-center borde">
                                <Card.Header>

                                </Card.Header>
                                <Card.Body>
                                <Card.Title>No hay registros</Card.Title>
                                </Card.Body>
                            </Card>
                ) 
                }

</>
        )}
        </>

    )
}

export default TabTalVez
