import React from 'react'
import {useDispatch, useSelector} from 'react-redux' 
import {inquilinosInteresados} from '../redux/inquilinosInteresadosDucks'
import { modificarPropiedadesPosibles } from '../redux/ModificarPropiedadPosibleDucks'
import { ingresarNotificacion } from '../redux/NotificacionesDucks'
import { obtenerNotificacionesMeGusta } from '../redux/ObtenerNotificacionesMeGusta'


const MostrarInfoInquilinosInteresados = (props) => {

    const ingresarNot = useSelector(store => store.ingresarNotificacion)
    const dispatch = useDispatch()

    React.useEffect(() => {
        const fetchData = () => {
            dispatch(inquilinosInteresados(props.propIdDefault))
        }
        fetchData()
    },[dispatch])

    const inquilinos = useSelector(store => store.inquilinosInteresados)

    const [data,setData] = React.useState(null)
    
    React.useEffect(() => {
    setData(Array.from(inquilinos.results))
    dispatch(obtenerNotificacionesMeGusta(localStorage.getItem("id_usuario").replaceAll('"',''), props.datos.idBusqueda))
    },[inquilinos])
 
    const match = (id_inquilino,id_busqueda,id_propiedad) => {
        dispatch(modificarPropiedadesPosibles(id_inquilino,id_busqueda,id_propiedad,0,0,0,1,0))
        //dispatch(inquilinosInteresados(props.propIdDefault))

        let datosNotificacion = {
            user1 : localStorage.getItem("id_usuario").replaceAll('"',''),
            user2: id_inquilino,
            tipo: 'match',
            leido: 0,
            propiedadId: id_propiedad
        }
    
        dispatch(ingresarNotificacion(datosNotificacion))

    }

    const noAceptar = (id_inquilino,id_busqueda,id_propiedad) => {
        dispatch(modificarPropiedadesPosibles(id_inquilino,id_busqueda,id_propiedad,0,0,0,0,1))
        //dispatch(inquilinosInteresados(props.propIdDefault))

        let datosNotificacion = {
            user1 : localStorage.getItem("id_usuario").replaceAll('"',''),
            user2: id_inquilino,
            tipo: 'no aceptado',
            leido: 0,
            propiedadId: id_propiedad
        }
    
        dispatch(ingresarNotificacion(datosNotificacion))
    }
    

    return inquilinos.results.length !== 0 ? (
      
        <div className="card mt-4 text-center">
            
            <ul className="list-group mt-3">
             {   inquilinos.status === 'ok' && data !== null &&
                data.map(item => (
                <li key={item.id} className="list-group-item text-uppercase">
                <div className="card-body">
                 
                <div className="card-title text-uppercase">{item.nombre}</div>
                    <p className="card-text">Celular: {item.celular} | Email: {item.email}</p>
                    <button 
                        className="btn btn-dark btn-sm float-right"
                        onClick={() => match(item.id_inquilino,item.id_busqueda,item.id_propiedad) }
                        >
                        Match
                    </button>
                    
                    <button 
                        className="btn btn-dark btn-sm float-right"
                        onClick={() => noAceptar(item.id_inquilino,item.id_busqueda,item.id_propiedad) }
                        >
                        No aceptar
                    </button>
               
                </div>
                </li>
                 )) 
            } 
            </ul>
          
        </div>
       
    ) : (
        <div className="card mt-4 text-center">
        <div className="card-body">
        <p>No tiene ningun interesado aun</p>
        </div>
        </div>)
}

export default MostrarInfoInquilinosInteresados
