import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {obtenerPropiedadesPropietario, siguientePaginaPropiedadesPropietario, anteriorPaginaPropiedadesPropietario} from '../redux/ObtenerPropiedadesPropietario'
import {Link} from 'react-router-dom'
import MostrarInfoInquilinosInteresados from './MostrarInfoInquilinosInteresados'
import { inquilinosInteresados } from '../redux/inquilinosInteresadosDucks'

const MostrarPropiedadesPropietario = () => {

     //Dispatch es el que llama al accion
     const dispatch = useDispatch()
     const propiedadesPropietario = useSelector(store => store.propiedadesPropietario)//.results) 
     const next = useSelector(store => store.propiedadesPropietario.next) 
     const previous = useSelector(store => store.propiedadesPropietario.previous)
     const [propId, setPropId] = React.useState('')
    
         React.useEffect(() => {
            const fetchData = () => {
                
                dispatch(obtenerPropiedadesPropietario())
            }
            fetchData()
        },[dispatch]) 
     
        let [error_msg, setError_msg] = React.useState('') 
    

        React.useEffect(() => {
            
            if(propiedadesPropietario.isFetching === false && propiedadesPropietario.status === "error"){
                setError_msg(propiedadesPropietario.results.error_msg)
            }

        },[propiedadesPropietario])

        React.useEffect(() => {
        if(propiedadesPropietario.results !== null){
        if(propiedadesPropietario.results.result){
            setPropId(propiedadesPropietario.results.result.datos[0].propiedadId)
            
        }}
    },[propiedadesPropietario.results])

        let msgError = []
        if(error_msg !== ''){
            msgError.push(<div className="alert alert-danger">{error_msg}</div>)
        }

        

        const datosInquilinos = (propiedadId) => {
     
        
        setPropId(propiedadId)
        dispatch(inquilinosInteresados(propiedadId))
        }

        
        
        return (
        <>
        {(propiedadesPropietario.isFetching === true && propiedadesPropietario.status === '') ? (<h1>cargando</h1>) :( 
        <>
        <div className="row">
            <div className="col-md-6">

                <h3>Lista propiedades</h3>
                {msgError}
                <ul className="list-group mt-3">
                        {             propiedadesPropietario.results !== null ? (
                                      propiedadesPropietario.status === 'ok' &&
                                      propiedadesPropietario.results.result.datos.map(item => (

                                        <li key={item.propiedadId} className="list-group-item text-uppercase">
                                            {item.propiedadId} -- {item.idUsuario} -- {item.precioDesdeAlquiler} -- {item.precioHastaAlquiler} -- {item.dormitorios} -- {item.banos}
                                            <button 
                                            className="btn btn-dark btn-sm float-right"
                                             onClick={() => datosInquilinos(item.propiedadId)} 
                                            >
                                                Info
                                            </button>
                                        </li>
                                    )) 
                                    ):(
                                        <p>No tiene ninguno</p>
                                    )
                        }
                    </ul>
        

                    {previous !== null && next!== null ? (
                     
                     (previous.result.previous!==0) ? (
                     <button onClick={() => dispatch(anteriorPaginaPropiedadesPropietario()) } className="btn btn-dark">Anterior</button>
                     ):null
                     ):null}
 
                     {/* previous !== null && */ next!== null ? (
                       
                     (next.result.next) ? (
                     <button onClick={() => dispatch(siguientePaginaPropiedadesPropietario()) } className="btn btn-dark">Siguiente</button>
                     ):null
                     ):null} 
        
            </div>

        {propId!=='' ? (
        <div className="col-md-6">
                <h3>Inquilinos interesados en mi propiedad nro. {propId}</h3>
                <MostrarInfoInquilinosInteresados propIdDefault={propId} />
        </div>
        ):null
        }
        </div>
        <div>
            <Link to = {'/menu/'}>Volver</Link>
        </div>
        </>
        )}
        </>
        )
}

export default MostrarPropiedadesPropietario
