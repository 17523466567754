import React from 'react'
import { obtenerNotificacionesMeGustaBadge } from '../redux/ObtenerNotificacionesMeGustaBadge';
import { Badge } from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux'



const BadgeComponentLike = (props) => {
    
    const dispatch = useDispatch()
    const meGustaBadge = useSelector(store => store.obtenerNotificacionesMeGustaBadge)

    React.useEffect(() => {
    
    dispatch(obtenerNotificacionesMeGustaBadge(localStorage.getItem("id_usuario").replaceAll('"',''), props.propIdDefault))
    
    },[])

    let [cantidadBadgeLike,setCantidadBadgeLike] = React.useState(null)
    React.useEffect(() => {
        console.log('ENTRA AL USE EFFECT')
        if(meGustaBadge.isFetching === false && meGustaBadge.results !== null){
            if(meGustaBadge.results !== null && meGustaBadge.status === 'ok' ){
                if(/* meGustaBadge.results.result.cantidad &&  */meGustaBadge.results.result.propiedadId === props.propIdDefault ){

        setCantidadBadgeLike(meGustaBadge.results.result.cantidad)}}}
    },[meGustaBadge])


    return (
        <>
        {(cantidadBadgeLike!== 0)?(
        <Badge bg='warning' text="dark">{cantidadBadgeLike}</Badge>
        ):(null)}
        </>
    )
}

export default BadgeComponentLike
