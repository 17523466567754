import axios from 'axios'
import {urlApi} from '../services/apirest'

//3-constantes
const dataInicial = {
    status: '',
    results: [],
    isFetching : false,
}

//INGRESAR PROPIEDAD
const INGRESAR_USUARIO_ESPERANDO = 'INGRESAR_USUARIO_ESPERANDO'
const INGRESAR_USUARIO_EXITO = 'INGRESAR_USUARIO_EXITO'
const INGRESAR_USUARIO_ERROR = 'INGRESAR_USUARIO_ERROR'
const RESET_DATOS_USUARIO = 'RESET_DATOS_USUARIO'

//reducer
export default function ingresarUsuarioReducer(state = dataInicial, action){
    switch(action.type){

        case INGRESAR_USUARIO_ESPERANDO:
            return {
                ...state,
                isFetching: true
            }

        case INGRESAR_USUARIO_EXITO:
            return {
                ...state, 
                isFetching: false,
                status: action.payload.status,
                results: action.payload.result
            }

        case INGRESAR_USUARIO_ERROR:
            return {
                ...state, 
                isFetching: false,
                status: action.payload.status,
                results: action.payload.result
            }
        
        case RESET_DATOS_USUARIO:
            return {
                ...state,
                isFetching: false,
                status: action.payload.status,
                results: action.payload.result
            }
    
    default:
        return state
}
}

export const ingresarUsuario = (datos) => async (dispatch) => {
    const url = urlApi + 'usuarios'

    dispatch({
        type: INGRESAR_USUARIO_ESPERANDO,
    })

    try {
        const res = await axios.post(url,datos)

    if(res.data.status === "ok"){
        dispatch({
            type: INGRESAR_USUARIO_EXITO,
            payload: {
                status: res.data.status,
                result: res.data.result.usuarioId
            }
        })
    }else{
        dispatch({
            type: INGRESAR_USUARIO_ERROR,
            payload: {  status: res.data.status,
                        result: res.data.result
                    }
        })
    }

    }catch (error)  {
        dispatch({
            type: INGRESAR_USUARIO_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }
}

export const resetDatos = () => (dispatch) => {
    dispatch({
    type: RESET_DATOS_USUARIO,
    payload: {  status: '',
                result: {}}
    })
}