import axios from 'axios'
import {urlApi} from '../services/apirest'

//3-constantes
const dataInicial = {
    status: '',
    results: [],
    isFetching : false,
    next: null,
    previous: null
}


//Types

//ALL
const OBTENER_PROPIEDADES_NOGUSTA_EXITO = 'OBTENER_PROPIEDADES_NOGUSTA_EXITO'
const OBTENER_PROPIEDADES_NOGUSTA_ERROR = 'OBTENER_PROPIEDADES_NOGUSTA_ERROR'
const OBTENER_PROPIEDADES_NOGUSTA_ESPERANDO = 'OBTENER_PROPIEDADES_NOGUSTA_ESPERANDO'

//NEXT
const OBTENER_NEXT_PROPIEDADES_NOGUSTA_EXITO = 'OBTENER_NEXT_PROPIEDADES_NOGUSTA_EXITO'
const OBTENER_NEXT_PROPIEDADES_NOGUSTA_ERROR = 'OBTENER_NEXT_PROPIEDADES_NOGUSTA_ERROR'
const OBTENER_NEXT_PROPIEDADES_NOGUSTA_ESPERANDO = 'OBTENER_NEXT_PROPIEDADES_NOGUSTA_ESPERANDO'

//PREVIOUS
const OBTENER_PREVIOUS_PROPIEDADES_NOGUSTA_EXITO = 'OBTENER_PREVIOUS_PROPIEDADES_NOGUSTA_EXITO'
const OBTENER_PREVIOUS_PROPIEDADES_NOGUSTA_ERROR = 'OBTENER_PREVIOUS_PROPIEDADES_NOGUSTA_ERROR'
const OBTENER_PREVIOUS_PROPIEDADES_NOGUSTA_ESPERANDO = 'OBTENER_PREVIOUS_PROPIEDADES_NOGUSTA_ESPERANDO'

//ONE
const OBTENER_PROPIEDAD_NOGUSTA_EXITO = 'OBTENER_PROPIEDAD_NOGUSTA_EXITO'
const OBTENER_PROPIEDAD_NOGUSTA_ERROR = 'OBTENER_PROPIEDAD_NOGUSTA_ERROR'
const OBTENER_PROPIEDAD_NOGUSTA_ESPERANDO = 'OBTENER_PROPIEDAD_NOGUSTA_ESPERANDO'





//reducer
export default function propiedadesNoMeGustaReducer(state = dataInicial, action){
    switch(action.type){

        case OBTENER_PROPIEDADES_NOGUSTA_ESPERANDO:
            return {
                ...state,
                isFetching: true
                
            }

        case OBTENER_PROPIEDADES_NOGUSTA_ERROR:
            return {...state, 
                isFetching: false,
                status: action.payload.status,
                results: action.payload.result
                
            }

        case OBTENER_PROPIEDADES_NOGUSTA_EXITO:
            return {
                ...state,
                isFetching: false,
                status: 'ok',
                results: action.payload,//.result.datos,
                next: action.payload,//.result.next,
                previous: action.payload,//.result.previous
                
            }

            case OBTENER_NEXT_PROPIEDADES_NOGUSTA_ESPERANDO:
                return {
                    ...state,
                    isFetching: true
                    
                }
    
            case OBTENER_NEXT_PROPIEDADES_NOGUSTA_ERROR:
                return {...state, 
                    isFetching: false,
                    status: action.payload.status,
                    results: action.payload.result
                    
                }
    
            case OBTENER_NEXT_PROPIEDADES_NOGUSTA_EXITO:
                return {
                    ...state,
                    isFetching: false,
                    status: 'ok',
                    results: action.payload,//.result.datos,
                    next: action.payload,//.result.next,
                    previous: action.payload,//.result.previous
                    
                }

            case OBTENER_PREVIOUS_PROPIEDADES_NOGUSTA_ESPERANDO:
                return {
                    ...state,
                    isFetching: true
                        
                }
        
            case OBTENER_PREVIOUS_PROPIEDADES_NOGUSTA_ERROR:
                return {...state, 
                    isFetching: false,
                    status: action.payload.status,
                    results: action.payload.result
                        
                }
        
            case OBTENER_PREVIOUS_PROPIEDADES_NOGUSTA_EXITO:
                return {
                    ...state,
                    isFetching: false,
                    status: 'ok',
                    results: action.payload,//.result.datos,
                    next: action.payload,//.result.next,
                    previous: action.payload,//.result.previous
                        
                }    
                
            case OBTENER_PROPIEDAD_NOGUSTA_ESPERANDO:
                return {
                    ...state,
                    isFetching: true
                            
                }
            
            case OBTENER_PROPIEDAD_NOGUSTA_ERROR:
                return {...state, 
                    isFetching: false,
                    status: action.payload.status,
                    results: action.payload.result
                            
                }
            
            case OBTENER_PROPIEDAD_NOGUSTA_EXITO:
                return {
                    ...state,
                    isFetching: false,
                    status: 'ok',
                    unaPropiedad: action.payload      
                 }

            default:
            return state
    

        }
    }

//mE FALTA EL OBTENER PROPIEDADES POSIBLES
//acciones
//con el dispatch activamos el reducer y con el getstate obtenemos la data inicial



//************************************************************* */
//acciones
//con el dispatch activamos el reducer y con el getstate obtenemos la data inicial
export const obtenerPropiedadesNoMeGusta = (id_busqueda,meGusta,noMeGusta,talVez,aceptada,noAceptada) => async (dispatch) => {
    
    const url = urlApi + 'propiedades'

    dispatch({
        type: OBTENER_PROPIEDADES_NOGUSTA_ESPERANDO,
    })

    try {  
        const idUsuario = localStorage.getItem("id_usuario").replaceAll('"','')
        const res = await axios.get(url+'?page=1&id_inquilino='+idUsuario+'&id_busqueda='+id_busqueda+'&meGusta='+meGusta+'&noMeGusta='+noMeGusta+'&talVez='+talVez+'&aceptada='+aceptada+'&noAceptada='+noAceptada+'')

            
            dispatch({
                type: OBTENER_PROPIEDADES_NOGUSTA_EXITO,
                //Devolvemos toda la data ahora
                payload: res.data
            })
            
    }catch (error) {
        
        dispatch({
            type: OBTENER_PROPIEDADES_NOGUSTA_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }
}



export const siguientePaginaPropiedadesNoMeGusta = (id_busqueda,meGusta,noMeGusta,talVez,aceptada,noAceptada) => async (dispatch,getState) => {

    const next = getState().propiedadesNoMeGusta.results.result.next



    const url = urlApi + 'propiedades'

    dispatch({
        type: OBTENER_NEXT_PROPIEDADES_NOGUSTA_ESPERANDO,
    })

    try {
        const idUsuario = localStorage.getItem("id_usuario").replaceAll('"','')
        const res = await axios.get(url+'?page='+next+'&id_inquilino='+idUsuario+'&id_busqueda='+id_busqueda+'&meGusta='+meGusta+'&noMeGusta='+noMeGusta+'&talVez='+talVez+'&aceptada='+aceptada+'&noAceptada='+noAceptada+'')
        dispatch({
            type: OBTENER_NEXT_PROPIEDADES_NOGUSTA_EXITO,
            payload: res.data
        })



    } catch (error) {

        dispatch({

            type: OBTENER_NEXT_PROPIEDADES_NOGUSTA_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }

}


export const anteriorPaginaPropiedadesNoMeGusta = (id_busqueda,meGusta,noMeGusta,talVez,aceptada,noAceptada) => async (dispatch,getState) => {

    const previous = getState().propiedadesNoMeGusta.results.result.previous

    const url = urlApi + 'propiedades'

    dispatch({
        type: OBTENER_PREVIOUS_PROPIEDADES_NOGUSTA_ESPERANDO,
    })

    try {
        const idUsuario = localStorage.getItem("id_usuario").replaceAll('"','')
        const res = await axios.get(url+'?page='+previous+'&id_inquilino='+idUsuario+'&id_busqueda='+id_busqueda+'&meGusta='+meGusta+'&noMeGusta='+noMeGusta+'&talVez='+talVez+'&aceptada='+aceptada+'&noAceptada='+noAceptada+'')
        
        dispatch({
            type: OBTENER_PREVIOUS_PROPIEDADES_NOGUSTA_EXITO,
            payload: res.data
        })

    } catch (error) {
        dispatch({
            type: OBTENER_PREVIOUS_PROPIEDADES_NOGUSTA_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }

}

/* export const obtenerPropiedadPosible = (id = '1') => async (dispatch) => {

    const url = urlApi + 'propiedades'

    if(localStorage.getItem('propiedad='+id)){
        dispatch({
            type: OBTENER_PROPIEDAD_POSIBLES_EXITO,
            //Devolvemos toda la data ahora
            payload: JSON.parse(localStorage.getItem('propiedad='+id))
        })
        //ponemos un return para salir de la funcion, tambien se podria haber puesto un else del if
        return
    }

    dispatch({
        type: OBTENER_PROPIEDAD_POSIBLES_ESPERANDO,
    })

    try {
        const res = await axios.get(url+'?id='+id)

        dispatch({
            type: OBTENER_PROPIEDAD_POSIBLES_EXITO,
            payload: {
                propiedadId: res.data[0].propiedadId,
                precioAlquiler: res.data[0].precioAlquiler,
                dormitorios: res.data[0].dormitorios,
                banos: res.data[0].banos,
                orientacion: res.data[0].orientacion,
                mascotas: res.data[0].mascotas,
                metrosEdificados: res.data[0].metrosEdificados,
                }
        })

        localStorage.setItem('propiedad='+id,JSON.stringify({
            propiedadId: res.data[0].propiedadId,
            precioAlquiler: res.data[0].precioAlquiler,
            dormitorios: res.data[0].dormitorios,
            banos: res.data[0].banos,
            orientacion: res.data[0].orientacion,
            mascotas: res.data[0].mascotas,
            metrosEdificados: res.data[0].metrosEdificados,
        }))

    } catch (error)  {
        dispatch({
            type: OBTENER_PROPIEDAD_POSIBLES_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }

}
 */
