import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {obtenerBusquedas, siguientePaginaBusquedas, anteriorPaginaBusquedas} from '../redux/BusquedasDucks'
import {obtenerPropiedadesPosibles} from '../redux/PropiedadesPosiblesDucks'
import {ingresarPropiedadesPosibles} from '../redux/IngresoPropiedadesPosiblesDucks'
import {Link} from 'react-router-dom'
import MostrarPropiedadesFiltro from './MostrarPropiedadesFiltro'

const MostrarBusquedas = () => {

     //Dispatch es el que llama al accion
     const dispatch = useDispatch()
     const busquedas = useSelector(store => store.busquedas)//.results) 
     const next = useSelector(store => store.busquedas.next) 
     const previous = useSelector(store => store.busquedas.previous)
    
         React.useEffect(() => {
            const fetchData = () => {
                dispatch(obtenerBusquedas())
            }
            fetchData()
        },[dispatch]) 
     
        let [error_msg, setError_msg] = React.useState('') 
    
    
        React.useEffect(() => {
    
            if(busquedas.isFetching === false && busquedas.status === "error"){
                setError_msg(busquedas.results.error_msg)
    
            }
        },[busquedas])
        
        let msgError = []
        if(error_msg !== ''){
            msgError.push(<div className="alert alert-danger">{error_msg}</div>)
        }

        const [datos,setDatos] = React.useState(null)

        const propiedadesPosibles = (precioDesdeAlquiler,dormitorios,banos,orientacion,mascotas,idUsuario,precioHastaAlquiler,propiedadId) => {

            setDatos({
                precioDesdeAlquiler: precioDesdeAlquiler,
                dormitorios: dormitorios,
                banos: banos,
                orientacion: orientacion,
                mascotas: mascotas,
                idUsuario: idUsuario,
                precioHastaAlquiler: precioHastaAlquiler,
                propiedadId: propiedadId,
                idBusqueda: propiedadId
            })
            
            dispatch(obtenerPropiedadesPosibles(propiedadId,0,0,0,0,0))
            
            
            //dispatch(obtenerPropiedadesPosibles(propiedadId))

        }
        if(datos !== null){
            dispatch(ingresarPropiedadesPosibles(datos))
        }


    return (
        <>
        {(datos === null) ? ( 
        <>
        <div className="row">
            <div className="col-md-6">

                <h3>Mis busquedas</h3>
                {msgError}
                <ul className="list-group mt-3">
                        {             busquedas.results !== null ? (
                                      busquedas.status === 'ok' &&
                                      busquedas.results.result.datos.map(item => (

                                        <li key={item.id_propiedad} className="list-group-item text-uppercase">
                                            {item.propiedadId} -- {item.idUsuario} -- {item.precioDesdeAlquiler} -- {item.precioHastaAlquiler} -- {item.dormitorios} -- {item.banos}
                                            
                                            <button 
                                            className="btn btn-dark btn-sm float-right"
                                              onClick={() => propiedadesPosibles(item.precioDesdeAlquiler,item.dormitorios,item.banos,item.orientacion,item.mascotas,item.idUsuario,item.precioHastaAlquiler,item.propiedadId)}
                                            >
                                                Info
                                            </button>

{/* 
                                            <button 
                                            className="btn btn-dark btn-sm float-right"
                                            onClick={() => actualizarPropiedadesPosibles(item.precioDesdeAlquiler,item.dormitorios,item.banos,item.orientacion,item.mascotas,item.idUsuario,item.precioHastaAlquiler,item.propiedadId)}
                                            >
                                                Actualizar
                                            </button> */}

                                        </li>
                                    )) 
                                    ):(
                                        <p>No tiene ninguno</p>
                                    ) 
                        }
                    </ul>


                    {previous !== null && next!== null ? (
                     
                     (previous.result.previous!==0) ? (
                     <button onClick={() => dispatch(anteriorPaginaBusquedas()) } className="btn btn-dark">Anterior</button>
                     ):null
                     ):null}
 
                     {/* previous !== null && */ next!== null ? (
                       
                     (next.result.next) ? (
                     <button onClick={() => dispatch(siguientePaginaBusquedas()) } className="btn btn-dark">Siguiente</button>
                     ):null
                     ):null}

        
            </div>

            </div>
        <div>
            <Link to = {'/menu'}>Volver</Link>
        </div>
        </>
        ) :( 
        <div className="col-md-6">
            <h3>Detalle Propiedad</h3>
            <MostrarPropiedadesFiltro datos={datos}/>
        </div> 
        
        )}
        

        </>
        
       
    )
}

export default MostrarBusquedas
