import axios from 'axios'
import {urlApi} from '../services/apirest'

//3-constantes
const dataInicial = {
    status: '',
    results: [],
    isFetching : false,
}

//INGRESAR 
const INGRESAR_NOTIFICACIONES_ESPERANDO = 'INGRESAR_NOTIFICACIONES_ESPERANDO'
const INGRESAR_NOTIFICACIONES_EXITO = 'INGRESAR_NOTIFICACIONES_EXITO'
const INGRESAR_NOTIFICACIONES_ERROR = 'INGRESAR_NOTIFICACIONES_ERROR'

//MODIFICAR
const MODIFICAR_NOTIFICACIONES_EXITO = 'MODIFICAR_NOTIFICACIONES_EXITO'
const MODIFICAR_NOTIFICACIONES_ERROR = 'MODIFICAR_NOTIFICACIONES_ERROR'
const MODIFICAR_NOTIFICACIONES_ESPERANDO = 'MODIFICAR_NOTIFICACIONES_ESPERANDO'


//reducer
export default function ingresarNotificacionesReducer(state = dataInicial, action){
    switch(action.type){

        case INGRESAR_NOTIFICACIONES_ESPERANDO:
            return {
                ...state,
                isFetching: true
            }

        case INGRESAR_NOTIFICACIONES_EXITO:
            return {
                ...state, 
                isFetching: false,
                status: action.payload.status,
                results: action.payload.result
            }

        case INGRESAR_NOTIFICACIONES_ERROR:
            return {
                ...state, 
                isFetching: false,
                status: action.payload.status,
                results: action.payload.result
            }
            case MODIFICAR_NOTIFICACIONES_ESPERANDO:
                return {
                    ...state,
                    isFetching: true
                    
                }
    
            case MODIFICAR_NOTIFICACIONES_ERROR:
                return {...state, 
                    isFetching: false,
                    status: action.payload.status,
                    results: action.payload.result
                    
                }
    
            case MODIFICAR_NOTIFICACIONES_EXITO:
                return {
                    ...state,
                    isFetching: false,
                    status: 'ok',
                    results: action.payload.result.datos,
                    
                }
        
    
    default:
        return state
}
}

export const ingresarNotificacion = (datos) => async (dispatch) => {
    const url = urlApi + 'notificaciones'

    dispatch({
        type: INGRESAR_NOTIFICACIONES_ESPERANDO,
    })

    try {


        const res = await axios.post(url,datos)


    if(res.data.status === "ok"){
        dispatch({
            type: INGRESAR_NOTIFICACIONES_EXITO,
            payload: {
                status: res.data.status,
                result: res.data.result.usuarioId
            }
        })
    }else{
        dispatch({
            type: INGRESAR_NOTIFICACIONES_ERROR,
            payload: {  status: res.data.status,
                        result: res.data.result
                    }
        })
    }

    }catch (error)  {
        dispatch({
            type: INGRESAR_NOTIFICACIONES_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }
}

export const modificarNotificacion = (datos) => async (dispatch) => {
    
    const url = urlApi + 'notificaciones'

    dispatch({
        type: MODIFICAR_NOTIFICACIONES_ESPERANDO,
    })

    try {  
        const token = localStorage.getItem("token").toString().substring(1,33)
       
        const id_inquilino = localStorage.getItem("id_usuario").replaceAll('"','')
        
        /* {
        "id_not": 3,
        "leido": 1
        } */

        const res = await axios.put(url,datos)
           
            dispatch({
                type: MODIFICAR_NOTIFICACIONES_EXITO,
                //Devolvemos toda la data ahora
                payload: res.data
            })
            
    }catch (error) {
        
        dispatch({
            type: MODIFICAR_NOTIFICACIONES_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }}