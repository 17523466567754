import axios from 'axios'
import {urlApi} from '../services/apirest'

//3-constantes
const dataInicial = {
    status: '',
    results: [],
    isFetching : false,
    next: null,
    previous: null
}


//Types

//ALL
const OBTENER_BUSQUEDA_EXITO = 'OBTENER_BUSQUEDA_EXITO'
const OBTENER_BUSQUEDA_ERROR = 'OBTENER_BUSQUEDA_ERROR'
const OBTENER_BUSQUEDA_ESPERANDO = 'OBTENER_BUSQUEDA_ESPERANDO'

//NEXT
const OBTENER_NEXT_BUSQUEDA_EXITO = 'OBTENER_NEXT_BUSQUEDA_EXITO'
const OBTENER_NEXT_BUSQUEDA_ERROR = 'OBTENER_NEXT_BUSQUEDA_ERROR'
const OBTENER_NEXT_BUSQUEDA_ESPERANDO = 'OBTENER_NEXT_BUSQUEDA_ESPERANDO'

//PREVIOUS
const OBTENER_PREVIOUS_BUSQUEDA_EXITO = 'OBTENER_PREVIOUS_BUSQUEDA_EXITO'
const OBTENER_PREVIOUS_BUSQUEDA_ERROR = 'OBTENER_PREVIOUS_BUSQUEDA_ERROR'
const OBTENER_PREVIOUS_BUSQUEDA_ESPERANDO = 'OBTENER_PREVIOUS_BUSQUEDA_ESPERANDO'






//reducer
export default function busquedasReducer(state = dataInicial, action){
    switch(action.type){

        case OBTENER_BUSQUEDA_ESPERANDO:
            return {
                ...state,
                isFetching: true
                
            }

        case OBTENER_BUSQUEDA_ERROR:
            return {...state, 
                isFetching: false,
                status: action.payload.status,
                results: action.payload.result
                
            }

        case OBTENER_BUSQUEDA_EXITO:
            return {
                ...state,
                isFetching: false,
                status: 'ok',
                results: action.payload,//.result.datos,
                next: action.payload,//.result.next,
                previous: action.payload,//.result.previous
                
            }

            case OBTENER_NEXT_BUSQUEDA_ESPERANDO:
                return {
                    ...state,
                    isFetching: true
                    
                }
    
            case OBTENER_NEXT_BUSQUEDA_ERROR:
                return {...state, 
                    isFetching: false,
                    status: action.payload.status,
                    results: action.payload.result
                    
                }
    
            case OBTENER_NEXT_BUSQUEDA_EXITO:
                return {
                    ...state,
                    isFetching: false,
                    status: 'ok',
                    results: action.payload,//.result.datos,
                    next: action.payload,//.result.next,
                    previous: action.payload,//.result.previous
                    
                }

            case OBTENER_PREVIOUS_BUSQUEDA_ESPERANDO:
                return {
                    ...state,
                    isFetching: true
                        
                }
        
            case OBTENER_PREVIOUS_BUSQUEDA_ERROR:
                return {...state, 
                    isFetching: false,
                    status: action.payload.status,
                    results: action.payload.result
                        
                }
        
            case OBTENER_PREVIOUS_BUSQUEDA_EXITO:
                return {
                    ...state,
                    isFetching: false,
                    status: 'ok',
                    results: action.payload,//.result.datos,
                    next: action.payload,//.result.next,
                    previous: action.payload,//.result.previous
                        
                }  
                   
        default:
            return state
    }
}


//acciones
//con el dispatch activamos el reducer y con el getstate obtenemos la data inicial
export const obtenerBusquedas = () => async (dispatch) => {
    
    const url = urlApi + 'propiedades'

    dispatch({
        type: OBTENER_BUSQUEDA_ESPERANDO,
    })

    try {  
        const idUsuario = localStorage.getItem("id_usuario")
        const res = await axios.get(url+'?page=1&id_inquilino_busqueda='+idUsuario)

            dispatch({
                type: OBTENER_BUSQUEDA_EXITO,
                //Devolvemos toda la data ahora
                payload: res.data
            })

    }catch (error) {

        dispatch({
            type: OBTENER_BUSQUEDA_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }
}

export const siguientePaginaBusquedas = () => async (dispatch,getState) => {

    const next = getState().busquedas.results.result.next
    const url = urlApi + 'propiedades'

    dispatch({
        type: OBTENER_NEXT_BUSQUEDA_ESPERANDO,
    })

    try {
        const idUsuario = localStorage.getItem("id_usuario")
        const res = await axios.get(url+'?page='+next+'&id_inquilino_busqueda='+idUsuario)
        

        dispatch({
            type: OBTENER_NEXT_BUSQUEDA_EXITO,
            payload: res.data
        })

    } catch (error) {
        dispatch({
            type: OBTENER_NEXT_BUSQUEDA_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }

}


export const anteriorPaginaBusquedas = () => async (dispatch,getState) => {

    const previous = getState().busquedas.results.result.previous

    const url = urlApi + 'propiedades'

    dispatch({
        type: OBTENER_PREVIOUS_BUSQUEDA_ESPERANDO,
    })

    try {
        const idUsuario = localStorage.getItem("id_usuario")
        const res = await axios.get(url+'?page='+previous+'&id_inquilino_busqueda='+idUsuario)
        dispatch({
            type: OBTENER_PREVIOUS_BUSQUEDA_EXITO,
            payload: res.data
        })

    } catch (error) {
        dispatch({
            type: OBTENER_PREVIOUS_BUSQUEDA_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }

}



