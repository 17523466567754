import axios from 'axios'
import {urlApi} from '../services/apirest'

//3-constantes
const dataInicial = {
    status: '',
    results: [],
    isFetching : false,
}


//INGRESAR PROPIEDAD
const INGRESAR_PROPIEDAD_ESPERANDO = 'INGRESAR_PROPIEDAD_ESPERANDO'
const INGRESAR_PROPIEDAD_EXITO = 'INGRESAR_PROPIEDAD_EXITO'
const INGRESAR_PROPIEDAD_ERROR = 'INGRESAR_PROPIEDAD_ERROR'
const RESET_DATOS = 'RESET_DATOS'

//reducer
export default function ingresarPropiedadesReducer(state = dataInicial, action){
    switch(action.type){

        case INGRESAR_PROPIEDAD_ESPERANDO:
            return {
                ...state,
                isFetching: true
            }

        case INGRESAR_PROPIEDAD_EXITO:
            return {
                ...state, 
                isFetching: false,
                status: action.payload.status,
                results: action.payload.result
            }

        case INGRESAR_PROPIEDAD_ERROR:
            return {
                ...state, 
                isFetching: false,
                status: action.payload.status,
                results: action.payload.result
            }
        
        case RESET_DATOS:
            return {
                ...state,
                isFetching: false,
                status: action.payload.status,
                results: action.payload.result
            }
    
    default:
        return state
}
}


export const ingresarPropiedad = (datos) => async (dispatch) => {
    const url = urlApi + 'propiedades'

    dispatch({
        type: INGRESAR_PROPIEDAD_ESPERANDO,
    })

    try {
        
        const res = await axios.post(url,datos)

    if(res.data.status === "ok"){
        dispatch({
            type: INGRESAR_PROPIEDAD_EXITO,
            payload: {
                status: res.data.status,
                result: res.data.result.propiedadId
            }
        })
    }else{
        dispatch({
            type: INGRESAR_PROPIEDAD_ERROR,
            payload: {  status: res.data.status,
                        result: res.data.result
                    }
        })
    }

    }catch (error)  {
        dispatch({
            type: INGRESAR_PROPIEDAD_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }
}


export const resetDatos = () => (dispatch) => {
    dispatch({
    type: RESET_DATOS,
    payload: {  status: '',
                result: {}}
    })
}