import React from 'react'
import { Card,Button,Row,Col,Carousel,ListGroup,ListGroupItem } from 'react-bootstrap';
import PRUEBA2 from '../img/PRUEBA2.jpg';
import Icon from '@mdi/react'
import { mdiBedKing,mdiShower,mdiCurrencyUsd,mdiCompassOutline,mdiDog,mdiEmailMarkAsUnread ,mdiRulerSquare,mdiTransferLeft,mdiTransferRight    } from '@mdi/js'
import {useDispatch, useSelector} from 'react-redux'
import {obtenerPropiedadesMatch, siguientePaginaPropiedadesMatch, anteriorPaginaPropiedadesMatch} from '../redux/PropiedadesMatchDucks'
import {ingresarPropiedadesPosibles} from '../redux/IngresoPropiedadesPosiblesDucks'
import {obtenerPropiedad} from '../redux/ObtenerUnaPropiedadDucks'
import { notificacionesLeidasModificar } from '../redux/ModificarNotificacionesLeidasDucks';
import { obtenerNotificacionesAceptada } from '../redux/ObtenerNotificacionesAceptada';
import { obtenerMatchLeido } from '../redux/ObtenerMatchLeido';
import { obtenerNotificacionesAceptadaGeneral } from '../redux/ObtenerNotificacionesAceptadaGeneral';

const TabMatch = (props) => {

  //Dispatch es el que llama al accion
 const dispatch = useDispatch()
 const propiedades = useSelector(store => store.propiedadesMatch)//.results) 
 const next = useSelector(store => store.propiedadesMatch.next) 
 const previous = useSelector(store => store.propiedadesMatch.previous)
 const ingresoPropiedadesPosibles = useSelector(store => store.ingresarPropiedadesPosibles)
 const notificacionesAceptadas = useSelector(store => store.obtenerNotificacionesAceptada)
 const obtenerCantMatchLeido = useSelector(store => store.obtenerMatchLeido)
 const notificacionesLeidasMod = useSelector(store => store.notificacionesLeidasModificar)

 

    let [error_msg, setError_msg] = React.useState('') 


    React.useEffect(() => {

        if(propiedades.isFetching === false && propiedades.status === "error"){
            setError_msg(propiedades.results.error_msg)
        }
        dispatch(obtenerNotificacionesAceptada(localStorage.getItem("id_usuario").replaceAll('"',''), props.datos.idBusqueda))

    },[propiedades])

    React.useEffect(() => {
        if(ingresoPropiedadesPosibles.isFetching === false && ingresoPropiedadesPosibles.results !== null){
            dispatch(obtenerPropiedadesMatch(props.datos.propiedadId,0,0,0,1,0))
           
        }

    },[ingresoPropiedadesPosibles])

    
    
    let msgError = []
    if(error_msg !== ''){
        msgError.push(<div className="alert alert-danger">{error_msg}</div>)
    }

    const actualizar = () => {
        dispatch(ingresarPropiedadesPosibles(props.datos))
    }

   
const propiedad = useSelector(store => store.propiedades.unaPropiedad)
React.useEffect(() => {
    if(propiedades.results !== null){
    if(propiedades.results.result){

    dispatch(obtenerPropiedad(propiedades.results.result.datos[0].id_propiedad))
    dispatch(obtenerMatchLeido(localStorage.getItem("id_usuario").replaceAll('"',''),props.datos.idBusqueda,propiedades.results.result.datos[0].id_propiedad))
        }}
}, [propiedades.results])

const siguienteMatch = (id_propiedad) => {
    dispatch(siguientePaginaPropiedadesMatch(id_propiedad,0,0,0,1,0))
    //dispatch(obtenerMatchLeido(,id_propiedad2,id_busqueda))
}

const Leido = (id_busqueda,id_propiedad2) => {
    dispatch(notificacionesLeidasModificar(id_propiedad2,id_busqueda))
}

React.useEffect(() => {
    if(notificacionesLeidasMod.isFetching === false && notificacionesLeidasMod.results !== null){
        if(notificacionesLeidasMod.results !== null && notificacionesLeidasMod.status === 'ok' ){
        dispatch(obtenerMatchLeido(localStorage.getItem("id_usuario").replaceAll('"',''),props.datos.idBusqueda,propiedades.results.result.datos[0].id_propiedad))
        dispatch(obtenerNotificacionesAceptada(localStorage.getItem("id_usuario").replaceAll('"',''), props.datos.idBusqueda))
        dispatch(obtenerNotificacionesAceptadaGeneral(localStorage.getItem("id_usuario").replaceAll('"','')))
    }}
}, [notificacionesLeidasMod])

const [cantNotAceptadas,setCantNotAceptadas] = React.useState(null)
React.useEffect(() => {
    if(obtenerCantMatchLeido.isFetching === false && obtenerCantMatchLeido.results !== null){
        if(obtenerCantMatchLeido.results !== null && obtenerCantMatchLeido.status === 'ok' ){
            
            setCantNotAceptadas(obtenerCantMatchLeido.results.result.cantidad)}}
}, [obtenerCantMatchLeido])

 //obtenerNotificacionesAceptada(localStorage.getItem("id_usuario").replaceAll('"',''), props.datos.idBusqueda)

    return (
        <>
        {(propiedades.isFetching === true && propiedades.status === '') ? (<h1>cargando</h1>) :( 
        <>

{       propiedades.results !== null ? (
        propiedades.status === 'ok'  &&
        propiedades.results.result.datos.map(item => (
        <Card key={item.id_propiedad} className="text-center borde">
                        <Card.Header>
                        
                        </Card.Header>
                        <Card.Body>
                        <Card.Title>Busqueda id: {props.datos.idBusqueda} / Id prop:{item.id_propiedad}</Card.Title>
                        {propiedad !== undefined ? ( 
                          <>
                                <Carousel controls={false}>
                                    <Carousel.Item>
                                      <img
                                        className="d-block w-100"
                                        src={PRUEBA2}
                                        alt="First slide"
                                      />
                                      
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={PRUEBA2}
                                        alt="Second slide"
                                      />


                                    </Carousel.Item>
                                    
                                </Carousel>
                            {/* <Card.Text>
                              With supporting text below as a natural lead-in to additional content.
                            </Card.Text> */}
                            
                              
                            <ListGroup className="list-group-flush">
                                <ListGroupItem>
                                    <Row className="justify-content-center p-4">
                                    <Col md={4} xs={12}>
                                            <Icon path={mdiCurrencyUsd} title="silverware" size={1} className="align-middle colorIconos p-0.8"/> 
                                            {propiedad.precioDesdeAlquiler}
                                    </Col>
                                    <Col md={4} xs={12}>
                                            <Icon path={mdiBedKing} title="silverware" size={1} className="align-middle colorIconos p-0.8"/>
                                            {propiedad.dormitorios}
                                    </Col>
                                    <Col md={4} xs={12}>
                                            <Icon path={mdiShower} title="silverware" size={1} className="align-middle colorIconos p-0.8"/>
                                            {propiedad.banos}
                                    </Col>
                                    </Row>
                              </ListGroupItem>
                              <ListGroupItem>
                                    <Row className="justify-content-center p-4">
                                    <Col md={4} xs={12}>
                                            <Icon path={mdiDog} title="silverware" size={1} className="align-middle colorIconos p-0.8"/> 
                                            {propiedad.mascotas}
                                    </Col>
                                    <Col md={4} xs={12}>
                                            <Icon path={mdiCompassOutline} title="silverware" size={1} className="align-middle colorIconos p-0.8"/>
                                            {propiedad.orientacion}
                                    </Col>
                                    <Col md={4} xs={12}>
                                            <Icon path={mdiRulerSquare} title="silverware" size={1} className="align-middle colorIconos p-0.8"/>
                                            {propiedad.metrosEdificados}
                                    </Col>
                                    </Row>
                              </ListGroupItem>
                            </ListGroup>
                            <Card.Footer className="colorH3">
                            <Row className="justify-content-center p-4">
                            
                            {previous !== null && next!== null ? (
                     
                            (previous.result.previous!==0) ? (
                            <Col md={4} xs={12}>
                                    <Button variant="secondary" onClick={() => dispatch(anteriorPaginaPropiedadesMatch(props.datos.propiedadId,0,0,0,1,0)) }>
                                        <Icon path={mdiTransferLeft } title="silverware" size={1} className="align-middle colorIconos"/>
                                    </Button>
                                </Col>
                            ):null
                            ):null}

                            {cantNotAceptadas === 0 ? (
                            <Col md={4} xs={12}>
                            <Button variant="warning" onClick={() => Leido(props.datos.idBusqueda,item.id_propiedad) }>
                                <Icon path={mdiEmailMarkAsUnread  } title="silverware" size={1} className="align-middle colorIconos"/>
                            </Button>
                            </Col>):null}

                            {/* previous !== null && */ next!== null ? (
                      
                            (next.result.next) ? (
                                <Col md={4} xs={12}>
                                    <Button variant="secondary" onClick={() => siguienteMatch(props.datos.propiedadId) }>
                                        <Icon path={mdiTransferRight  } title="silverware" size={1} className="align-middle colorIconos"/>
                                    </Button>
                                </Col>
                            ):null
                            ):null}
                               
                            </Row>  
                            </Card.Footer>  
                            </>
                        ):(
                          <Card className="text-center borde">
                          <Card.Header>

                          </Card.Header>
                          <Card.Body>
                          <Card.Title>No hay registros</Card.Title>
                          </Card.Body>
                          </Card>
                        )} 
                        </Card.Body>
                        </Card>

              ))
              ):(
                <Card className="text-center borde">
                <Card.Header>

                </Card.Header>
                <Card.Body>
                <Card.Title>No hay registros</Card.Title>
                </Card.Body>
                </Card>
              )  
              }

</>
        )}
        </>

    )
}

export default TabMatch
