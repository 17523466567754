import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import "./index.css";
import Login3 from "./components/Login3";
import { Provider } from "react-redux"
import generateStore from "./redux/store";
import Inicio3 from "./components/Inicio3";
import Registrar from "./components/Registrar";
import IngresarPropiedad from "./components/IngresarPropiedad";
import Menu from "./components/Menu";
import MostrarPropiedadesFiltro from "./components/MostrarPropiedadesFiltro";
import MostrarBusquedas from "./components/MostrarBusquedas";
import MostrarPropiedadesPropietario from "./components/MostrarPropiedadesPropietario";
import MeGusta from "./components/MeGusta";
import TabsInquilino from "./components/TabsInquilino";
import MisBusquedas from "./components/MisBusquedas";
import IngresarBusquedas from "./components/IngresarBusquedas";
import IngresarPropiedades from "./components/IngresarPropiedades";
import MisPropiedades from "./components/MisPropiedades";
import General from "./components/General";
import { Navbar,NavDropdown,Nav,Container,ToastContainer,Toast } from 'react-bootstrap';
import TipoUsuario from './components/TipoUsuario';



function App() {
  
  const store = generateStore()

  return (
    
    <Provider store={store}>
      
      
    <Router>

    <Container fluid>
   
      <Switch>
      <Route path="/" exact>
          <Login3 />
      </Route>
        

        <Route path="/menu" exact>
          <Menu />
        </Route>

        <Route path="/ingresar" exact>
          <IngresarPropiedad tipoPropietario={true} tipoInquilino={false}/>
        </Route>


        <Route path="/ver" exact>
          {/* <Registrar/> */}
          <Inicio3/>
        </Route>

        <Route path="/registrar" exact>
          <Registrar/>
        </Route>

        <Route path="/buscar" exact>
          <IngresarPropiedad tipoPropietario={false} tipoInquilino={true}/>
        </Route>

        <Route path="/posibles" exact>
          <MostrarPropiedadesFiltro />
        </Route>

        <Route path="/busquedas" exact>
          <MostrarBusquedas />
        </Route>

        <Route path="/propiedadesPropietario" exact>
          <MostrarPropiedadesPropietario />
        </Route>

        <Route path="/clasificacion" exact>
          <TabsInquilino />
        </Route>

        <Route path="/misBusquedas" exact>
        <MisBusquedas/>
        </Route>

        <Route path="/ingresarPropiedades" exact>
        <IngresarPropiedades tipoPropietario={true} tipoInquilino={false}/>
        </Route>

        <Route path="/ingresarBusquedas" exact>
        <IngresarBusquedas tipoPropietario={false} tipoInquilino={true}/>
        </Route>

        <Route path="/misPropiedades" exact>
        <MisPropiedades/>
        </Route>

        <Route path="/general" exact>
        <TipoUsuario/>
        </Route>


      </Switch>

      </Container>
      
    </Router>
    </Provider>
  

        
  );
}

export default App;
