import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'

//Importamos el reducer
import LoginReducer from './LoginDucks'
import propiedadesReducer from './PropiedadesDucks'
import ingresarPropiedadesReducer from './IngresoPropiedadDucks'
import ingresarUsuarioReducer from './IngresoUsuarioDucks'
import propiedadesPosiblesReducer from './PropiedadesPosiblesDucks'
import busquedasReducer from './BusquedasDucks'
import ingresarPropiedadesPosiblesReducer from './IngresoPropiedadesPosiblesDucks'
import propiedadesPropietarioReducer from './ObtenerPropiedadesPropietario'
import datosInquilinosReducer from './inquilinosInteresadosDucks'
import propiedadesMeGustaReducer from './PropiedadesMeGustaDucks'
import propiedadesNoMeGustaReducer from './PropiedadesNoMeGustaDucks'
import propiedadesTalVezReducer from './PropiedadesTalVezDucks'
import propiedadesMatchReducer from './PropiedadesMatchDucks'
import ingresarNotificacionesReducer from './NotificacionesDucks'
import obtenerNotificacionesReducer from './ObtenerNotificacionesDucks'
import unaPropiedadReducer from './ObtenerUnaPropiedadDucks'
import propiedadesPosiblesModificarReducer from './ModificarPropiedadPosibleDucks'
import datosInquilinosMatchReducer from './InquilinosMatch'
import obtenerNotificacionesMeGustaReducer from './ObtenerNotificacionesMeGusta'
import propiedadesNotificacionModificarReducer from './ModificarPropiedadesFlagNotificacion'
import obtenerNotificacionesAceptadaReducer from './ObtenerNotificacionesAceptada'
import obtenerNotificacionesAceptadaGeneralReducer from './ObtenerNotificacionesAceptadaGeneral'
import obtenerNotificacionesMeGustaGeneralReducer from './ObtenerNotificacionesMeGustaGeneral'
import obtenerNotificacionesMeGustaBadgeReducer from './ObtenerNotificacionesMeGustaBadge'
import obtenerNotificacionesAceptadaBadgeReducer from './ObtenerNotificacionesAceptadaBadge'
import notificacionesLeidasModificarReducer from './ModificarNotificacionesLeidasDucks'
import obtenerMatchLeidoReducer from './ObtenerMatchLeido'



//Aqui traemos todos los Ducks, ya que pueden haber varios
const rootReducer = combineReducers({
    login: LoginReducer,
    propiedades: propiedadesReducer,
    ingresarPropiedad: ingresarPropiedadesReducer,
    ingresarUsuario: ingresarUsuarioReducer,
    propiedadesPosibles: propiedadesPosiblesReducer,
    busquedas: busquedasReducer,
    ingresarPropiedadesPosibles: ingresarPropiedadesPosiblesReducer,
    propiedadesPropietario: propiedadesPropietarioReducer,
    inquilinosInteresados: datosInquilinosReducer,
    propiedadesMeGusta: propiedadesMeGustaReducer,
    propiedadesNoMeGusta: propiedadesNoMeGustaReducer,
    propiedadesTalvez: propiedadesTalVezReducer,
    propiedadesMatch: propiedadesMatchReducer,
    ingresarNotificaciones: ingresarNotificacionesReducer,
    obtenerNotificaciones: obtenerNotificacionesReducer,
    obtenerPropiedad: unaPropiedadReducer,
    modificarPropiedadesPosibles: propiedadesPosiblesModificarReducer,
    datosInquilinosMatch: datosInquilinosMatchReducer,
    obtenerNotificacionesMeGusta: obtenerNotificacionesMeGustaReducer,
    modificarPropiedadesNotificacion: propiedadesNotificacionModificarReducer,
    obtenerNotificacionesAceptada: obtenerNotificacionesAceptadaReducer,
    obtenerNotificacionesAceptadaGeneral: obtenerNotificacionesAceptadaGeneralReducer,
    obtenerNotificacionesMeGustaGeneral: obtenerNotificacionesMeGustaGeneralReducer,
    obtenerNotificacionesMeGustaBadge: obtenerNotificacionesMeGustaBadgeReducer,
    obtenerNotificacionesAceptadaBadge: obtenerNotificacionesAceptadaBadgeReducer,
    notificacionesLeidasModificar: notificacionesLeidasModificarReducer,
    obtenerMatchLeido: obtenerMatchLeidoReducer

    

    //Por ejemplo si hubiera un usuarioDucks, vamos a poner: (Asi se van agregando)
    //usuarios: usuarioReducer
})


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore(){
    const store = createStore( rootReducer, composeEnhancers( applyMiddleware(thunk) ))
    return store;
} 