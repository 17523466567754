import React from 'react'
import {Link} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../redux/LoginDucks'
import TipoUsuario from './TipoUsuario';
import { Navbar,Card,Button,Container,Row,Col,Form } from 'react-bootstrap';
import { mdiLock ,mdiAt } from '@mdi/js'
import Icon from '@mdi/react'

const Login3 = () => {

    const [userName, setUserName] = React.useState('')
    const [password, setPassword] = React.useState('')
    let msgError = []
 
    const dispatch = useDispatch();

    React.useEffect(() => {
        localStorage.clear()
        
        },[]) 

    const handleLogin = (e) => {

        localStorage.removeItem("token")
        localStorage.removeItem("id_usuario")

        e.preventDefault()

        let datos = {
            email: userName,
            password: password
        }


        dispatch(login(datos))


        e.target.reset()

        datos = {
            email: '',
            password: ''
        }


    }

    
    let [error_msg, setError_msg] = React.useState('') 
    let [conectado, setConectado] = React.useState(false) 

    
    const resultadoLogin = useSelector((store) => store.login)

    React.useEffect(() => {
        if(resultadoLogin.isFetching === true) {

        }else{
            
            if(resultadoLogin.status === "error"){
                setError_msg(resultadoLogin.result.error_msg)
 
            }

            if(resultadoLogin.status === "ok"){

                setConectado(resultadoLogin.conectado)
                //Seteo a cero
                setUserName('')
                setPassword('')
                setError_msg('')
            }
        }

        
        
    }, [resultadoLogin])


   
    if(error_msg !== ''){
        msgError.push(<div className="alert alert-danger">{error_msg}</div>)
    }
    

    
    

    return (
        <>
        {(conectado === true && localStorage.getItem("token") !== '') ? (<TipoUsuario />) :( 
        <>
        <Container fluid>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
         <  Container>
                <Navbar.Brand >Alquilalo.uy</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                </Navbar.Collapse>
         </Container>
        </Navbar> 

        <Row className="justify-content-center p-1">
            <Col md={6} xs={12}>
                <Card className="text-center borde">
                <Card.Body>
                <Card.Title>Iniciar sesion</Card.Title>

                <Form onSubmit={handleLogin}>
                              <Row className="justify-content-center p-4">
                                <Col md={6}>
                                <Row className="justify-content-center" xs={1}>
                                  <Col md={1} xs={1}>
                                      <Form.Group>
                                      <Icon path={mdiAt } title="silverware" size={1}  className="align-middle mb-3"/>
                                      </Form.Group>
                                      <br />
                                      <Form.Group>
                                      <Icon path={mdiLock  } title="silverware" size={1}  className="align-middle mb-1"/>
                                      </Form.Group>
                                </Col>
                                <Col md={9} xs={9}>

                                    <Form.Control placeholder="email" className="mb-3" onChange={e => setUserName(e.target.value)} value={userName}/>
                                    <Form.Control placeholder="password" type="password" className="mb-3" onChange={e => setPassword(e.target.value)} value={password}/>
                                    </Col>
                                  </Row>
                                </Col>
                                </Row>
                                
                                <Row className="justify-content-center p-1">
                                  <Col md={4}>
                                      <Button variant="secondary" size="mg" block type="submit">
                                          Ingresar
                                      </Button>
                                  </Col>
                                </Row>

                                <Row className="justify-content-center p-1">
                                  <Col md={4}>
                                  <Link to='/registrar'>Registarse </Link>
                                  </Col>
                                </Row>

                                {msgError}
                                  
                            </Form>
                </Card.Body>
                </Card>
            </Col>
        </Row>
        </Container>
        </>
        )}
        </>
    )
}

export default Login3
