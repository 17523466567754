import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {obtenerPropiedadesPosibles, siguientePaginaPropiedadesPosibles, anteriorPaginaPropiedadesPosibles} from '../redux/PropiedadesPosiblesDucks'
import {ingresarPropiedadesPosibles} from '../redux/IngresoPropiedadesPosiblesDucks'
import {modificarPropiedadesPosibles} from '../redux/ModificarPropiedadPosibleDucks'
import {Link} from 'react-router-dom'
import TalVez from './TalVez'
import NoMeGusta from './NoMeGusta'
import MeGusta from './MeGusta'
import Match from './Match'
import { ingresarNotificacion } from '../redux/NotificacionesDucks'
import { obtenerNotificaciones } from '../redux/ObtenerNotificacionesDucks'

const MostrarPropiedadesFiltro = (props) => {

 //Dispatch es el que llama al accion
 const dispatch = useDispatch()
 const propiedades = useSelector(store => store.propiedadesPosibles)//.result) 
 const next = useSelector(store => store.propiedadesPosibles.next) 
 const previous = useSelector(store => store.propiedadesPosibles.previous)
 const ingresoPropiedadesPosibles = useSelector(store => store.ingresarPropiedadesPosibles)
 //const ingresarNotificaciones = useSelector(store => store.ingresarNotificaciones)
 const notificaciones = useSelector(store => store.obtenerNotificaciones)

    let [error_msg, setError_msg] = React.useState('') 


    React.useEffect(() => {

        if(propiedades.isFetching === false && propiedades.status === "error"){
            setError_msg(propiedades.results.error_msg+'error')
        }

    },[propiedades])

    React.useEffect(() => {
         if(ingresoPropiedadesPosibles.isFetching === false && ingresoPropiedadesPosibles.results !== null){
     
            dispatch(obtenerPropiedadesPosibles(props.datos.propiedadId,0,0,0,0,0))
        } 

    },[ingresoPropiedadesPosibles])

    
    let msgError = []
    if(error_msg !== ''){
        msgError.push(<div className="alert alert-danger">{error_msg}</div>)
    }

    const actualizar = () => {
        dispatch(ingresarPropiedadesPosibles(props.datos))
    }

    let array = []
    let count = 0

    React.useEffect(() => {

        setInterval(() => {
            dispatch(obtenerNotificaciones(localStorage.getItem("id_usuario").replaceAll('"','')))


            if(notificaciones.results !== null && notificaciones.status === 'ok'){

            count = notificaciones.results.result.datos.length

        }},60000)

    }, [])

    React.useEffect(() => {
        if(notificaciones.isFetching === false && notificaciones.results !== null){
            
        }
    },[count])

    const meGusta = (id_busqueda,id_propiedad,propietarioId) => {
        dispatch(modificarPropiedadesPosibles(null,id_busqueda,id_propiedad,1,0,0,0,0))
        dispatch(ingresarPropiedadesPosibles(props.datos))


        let datosNotificacion = {
            user1 : localStorage.getItem("id_usuario").replaceAll('"',''),
            user2: propietarioId,
            tipo: 'me gusta',
            leido: 0,
            propiedadId: id_propiedad
        }

        dispatch(ingresarNotificacion(datosNotificacion))
    }

    const talVez = (id_busqueda,id_propiedad) => {
        dispatch(modificarPropiedadesPosibles(null,id_busqueda,id_propiedad,0,0,1,0,0))
        dispatch(ingresarPropiedadesPosibles(props.datos))
    }

    const noMeGusta = (id_busqueda,id_propiedad) => {
        dispatch(modificarPropiedadesPosibles(null,id_busqueda,id_propiedad,0,1,0,0,0))
        dispatch(ingresarPropiedadesPosibles(props.datos))
    }


    return (
        <>
        {(propiedades.isFetching === true && propiedades.status === '') ? (<h1>cargando</h1>) :( 
        <>
        {array}
        <div className="row">
            
            <div className="col-md-10">
            <button 
                    className="btn btn-dark btn-sm float-right"
                    onClick={() => actualizar()} 
                >
                    Actualizar
                </button>
                <h3>Lista propiedades posibles</h3>
                <p>Propiedad id: {props.datos.propiedadId}</p>

                {msgError}
               
                
                
                 <ul className="list-group mt-3">
                    
                        {             propiedades.results !== null ? (
                                      propiedades.status === 'ok' &&
                                      propiedades.results.result.datos.map(item => (

                                        <li key={item.id_propiedad} className="list-group-item text-uppercase">
                                            
                                            {item.id_busqueda} --{item.id_propiedad} -- {item.id_inquilino} -- {item.propietarioId} -- {item.meGusta} -- {item.noMeGusta} -- {item.talVez} -- {item.aceptada} -- {item.noAceptada}
                                            <button 
                                            className="btn btn-dark btn-sm float-right"
                                            >
                                                Info
                                            </button>
                                            
                                            <button 
                                            className="btn btn-success btn-sm float-right"
                                            onClick={() => meGusta(item.id_busqueda,item.id_propiedad,item.propietarioId) }
                                            >
                                                ME GUSTA
                                            </button>
                                            
                                            <button 
                                            className="btn btn-warning btn-sm float-right"
                                            onClick={() => talVez(item.id_busqueda,item.id_propiedad) }
                                            >
                                                TAL VEZ
                                            </button>
                                            
                                            <button 
                                            className="btn btn-danger btn-sm float-right"
                                            onClick={() => noMeGusta(item.id_busqueda,item.id_propiedad) }
                                            >
                                                NO ME GUSTA
                                            </button>


                                        </li>
                                    )) 
                                    ):(
                                        <p>No tiene ninguno</p>
                                    )
                        }
                    </ul> 
                     

            {previous !== null && next!== null ? (
                     
                     (previous.result.previous!==0) ? (
                     <button onClick={() => dispatch(anteriorPaginaPropiedadesPosibles(props.datos.propiedadId,0,0,0,0,0)) } className="btn btn-dark">Anterior</button>
                     ):null
                     ):null}
 
                     {/* previous !== null && */ next!== null ? (
                       
                     (next.result.next) ? (
                     <button onClick={() => dispatch(siguientePaginaPropiedadesPosibles(props.datos.propiedadId,0,0,0,0,0)) } className="btn btn-dark">Siguiente</button>
                     ):null
                     ):null}
         
        
            </div>
           

         <div className="col-md-6">
                <MeGusta datos={props.datos}/>
        </div>
          <div className="col-md-6">
                <NoMeGusta datos={props.datos}/>
        </div>
        
        <div className="col-md-6">
                <TalVez datos={props.datos}/>
        </div>
        
        <div className="col-md-6">
                <Match datos={props.datos}/>
                </div>  
        
        </div>
        <div>
            <Link to = {'/menu/'}>Volver</Link>
        </div>
        </>
         )} 
        </>
    )
}

export default MostrarPropiedadesFiltro
