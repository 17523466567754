import React from 'react'
import { Tabs,Tab,Card,Button,Container,Row,Col,Table,Badge } from 'react-bootstrap';
import Icon from '@mdi/react'
import { mdiBedKing,mdiShower,mdiCurrencyUsd,mdiTransferLeft,mdiTransferRight,mdiMagnify    } from '@mdi/js'
import {useDispatch, useSelector} from 'react-redux'
import {obtenerPropiedadesPropietario, siguientePaginaPropiedadesPropietario, anteriorPaginaPropiedadesPropietario} from '../redux/ObtenerPropiedadesPropietario'



import MisInquilinosInteresados from './MisInquilinosInteresados';
import {inquilinosInteresados} from '../redux/inquilinosInteresadosDucks';
import MisInquilinosMatch from './MisInquilinosMatch';
import { inquilinosInteresadosMatch } from '../redux/InquilinosMatch';
import General from './General';
import { obtenerNotificacionesMeGusta } from '../redux/ObtenerNotificacionesMeGusta';
import BadgeComponentLike from './BadgeComponentLike';
import Login3 from './Login3';


const MisPropiedades = () => {

  //Dispatch es el que llama al accion
  const dispatch = useDispatch()
  const propiedadesPropietario = useSelector(store => store.propiedadesPropietario)//.results) 
  const next = useSelector(store => store.propiedadesPropietario.next) 
  const previous = useSelector(store => store.propiedadesPropietario.previous)
  const [propId, setPropId] = React.useState(null)
  const propiedadesPosibles = useSelector(store => store.propiedadesPosibles)
  const modificarPropiedadesPosibles = useSelector(store => store.modificarPropiedadesPosibles)
  const meGusta = useSelector(store => store.obtenerNotificacionesMeGusta)

 
      React.useEffect(() => {
         const fetchData = () => {
      
             dispatch(obtenerPropiedadesPropietario())
             
         }
         fetchData()
     },[dispatch]) 
  
     let [error_msg, setError_msg] = React.useState('') 
 

     React.useEffect(() => {
         
         if(propiedadesPropietario.isFetching === false && propiedadesPropietario.status === "error"){
             setError_msg(propiedadesPropietario.results.error_msg)
         }

     },[propiedadesPropietario])

     React.useEffect(() => {
     if(propiedadesPropietario.results !== null){
     if(propiedadesPropietario.results.result){
         setPropId(propiedadesPropietario.results.result.datos[0].propiedadId)
         dispatch(inquilinosInteresados(propiedadesPropietario.results.result.datos[0].propiedadId))
         dispatch(inquilinosInteresadosMatch(propiedadesPropietario.results.result.datos[0].propiedadId))
         dispatch(obtenerNotificacionesMeGusta(localStorage.getItem("id_usuario").replaceAll('"',''), propiedadesPropietario.results.result.datos[0].propiedadId))
     }}
 },[propiedadesPropietario.results])

     let msgError = []
     if(error_msg !== ''){
         msgError.push(<div className="alert alert-danger">{error_msg}</div>)
     }

     

     const datosInquilinos = (propiedadId) => {
    
     
     setPropId(propiedadId)
     dispatch(inquilinosInteresados(propiedadId))
     dispatch(inquilinosInteresadosMatch(propiedadId))
     //Para el badge
     dispatch(obtenerNotificacionesMeGusta(localStorage.getItem("id_usuario").replaceAll('"',''), propiedadId))
     
     }


     React.useEffect(() => {
     
      if(propiedadesPropietario.results !== null){
        if(propiedadesPropietario.results.result){
     
         dispatch(inquilinosInteresados(propiedadesPropietario.results.result.datos[0].propiedadId))
         dispatch(inquilinosInteresadosMatch(propiedadesPropietario.results.result.datos[0].propiedadId))
        }}
     },[modificarPropiedadesPosibles])


     let [cantidadBadgeLike, setCantidadBadgeLike] = React.useState(null)
     React.useEffect(() => {
        setCantidadBadgeLike(null)
        if(meGusta.isFetching === false && meGusta.results !== null){
            if(meGusta.results !== null && meGusta.status === 'ok' ){
                if(meGusta.results.result.cantidad){
                  
                    setCantidadBadgeLike(meGusta.results.result.cantidad)}
                }}
    },[meGusta])

    return (
        <>
        {(localStorage.getItem("token"))?(

        <>
        {(propiedadesPropietario.isFetching === true && propiedadesPropietario.status === '') ? (<h1>cargando</h1>) :( 
        <>
            <Container fluid>
            
            <General/>
            {/* <Row className="justify-content-center abs-center">
                <Col xs={12} sm={12} md={6.5} lg={6}> */}

                <Row className="justify-content-center p-1">
                    
               
                    <Col md={6} xs={12} >
                        
                        <Card className="text-center borde mb-3 justify-content-center">
                            <Card.Header>
                            </Card.Header>
                            <Card.Body>
                                <Card.Title>Mis propiedades</Card.Title>

                                <Table striped bordered hover>
                                  <thead>
                                    <tr>
                                      <th>Id</th>
                                      <th><Icon path={mdiCurrencyUsd} title="silverware" size={1} className="align-middle colorIconos"/> </th>
                                      <th><Icon path={mdiBedKing} title="silverware" size={1} className="align-middle colorIconos"/></th>
                                      <th><Icon path={mdiShower} title="silverware" size={1} className="align-middle colorIconos"/></th>
                                      
                                    </tr> 
                                  </thead>
                                  <tbody>
                                    {propiedadesPropietario.results !== null ? (
                                      propiedadesPropietario.status === 'ok' &&
                                      propiedadesPropietario.results.result.datos.map(item => (

                                        <tr key={item.propiedadId}>
                                          <td>
                                          {item.propiedadId}
                                          </td>
                                          <td>
                                          {item.precioDesdeAlquiler}
                                          </td>
                                          <td>
                                          {item.dormitorios}
                                          </td>
                                          <td>
                                          {item.banos}
                                          </td>
                                          <td className="p-1">
                                       
                                        <Row className="justify-content-md-center">
                                        <Col md="2">
                                          
                                          <Button variant="secondary" className="p-1" onClick={() => datosInquilinos(item.propiedadId)}>
                                            <Icon path={mdiMagnify  } title="silverware" size={1} className="align-middle colorIconos" />
                                         </Button>
                                         </Col>
                                         <Col md="2">
                                         <BadgeComponentLike propIdDefault={item.propiedadId}/>
                                         </Col>
                     
                                         </Row>
                             
                                         </td>
                                         
                                                                                                 
                                        </tr>
                                        
                                      ))
                                      ):(
                                          <p>No tiene ninguno</p>
                                      )
                                      }
                                  </tbody>
                                </Table>

                                <Row className="justify-content-center p-4">
                            
                            {previous !== null && next!== null ? (
                     
                            (previous.result.previous!==0) ? (
                            <Col md={4} xs={12}>
                                    <Button variant="secondary" onClick={() => dispatch(anteriorPaginaPropiedadesPropietario()) }>
                                        <Icon path={mdiTransferLeft } title="silverware" size={1} className="align-middle colorIconos" />
                                    </Button>
                                </Col>
                            ):null
                            ):null}

                            {/* previous !== null && */ next!== null ? (
                      
                            (next.result.next) ? (
                                <Col md={4} xs={12}>
                                    <Button variant="secondary" onClick={() => dispatch(siguientePaginaPropiedadesPropietario()) }>
                                        <Icon path={mdiTransferRight  } title="silverware" size={1} className="align-middle colorIconos"/>
                                    </Button>
                                </Col>
                            ):null
                            ):null}
                               
                            </Row>  
                            
                                {/* {previous !== null && next!== null ? (
                     
                                (previous.result.previous!==0) ? (
                                <button onClick={() => dispatch(anteriorPaginaPropiedadesPropietario()) } className="btn btn-dark">Anterior</button>
                                ):null
                                ):null}

                                { next!== null ? (

                                (next.result.next) ? (
                                <button onClick={() => dispatch(siguientePaginaPropiedadesPropietario()) } className="btn btn-dark">Siguiente</button>
                                ):null
                                ):null}  */}
                            </Card.Body>
                            
                        </Card>
                    </Col>


                    <Col md={6} xs={12} >
                        
                    <Container fluid>
                        {/*  <MisBusquedas/> */}
                         <Tabs defaultActiveKey="nuevo" id="uncontrolled-tab-example" className="mb-3 justify-content-center">

                         {/* TAB NUEVO */}
                         <Tab eventKey="nuevo" title={<React.Fragment>Nuevo   <Badge bg='warning' text="dark">{cantidadBadgeLike}</Badge></React.Fragment>}>
                             <Row className="justify-content-center p-1">
                                 <Col md={12} xs={12}>
                                     <MisInquilinosInteresados propIdDefault={propId}/>
                                  </Col>
                              </Row>
                          </Tab>


                        {/* TAB MATCH INQUILINOS */}
                        <Tab eventKey="match" title="Match">
                             <Row className="justify-content-center p-1">
                                 <Col md={12} xs={12}>
                                     
                                  <MisInquilinosMatch propIdDefault={propId}/>
                                        
                                 </Col>
                              </Row>
                                         
                                        

                                    

                        </Tab>



                            </Tabs>
                            </Container>


                    </Col>
                
                </Row>
                </Container>
                </>
        )}
        </>


        ):(<Login3/>)}
                </>    

    )
}

export default MisPropiedades
